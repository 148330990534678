import React, { useEffect, useState } from "react"
import { Avatar, Flex, Modal, Select, Upload } from "antd"
import { Yellow28EditIcon } from "../../assets/rawSvgs"
import CustomInput from "../customInput/CustomInput"
import CustomSelect from "../customSelect/CustomSelect"
import { UserOutlined } from "@ant-design/icons"
import { openNotification } from "../../util/openNotifications"
import { useAddMediaMutation } from "../../api/apiSlices/settingsApiSlice"
import CustomTextArea from "../customInput/CustomTextArea"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  useCreateCommunityMutation,
  useGetCommunityCategoriesQuery
} from "../../api/apiSlices/communityApiSlice"
import { useNavigate } from "react-router-dom"
import "./styles.scss"

export const validationSchema = Yup.object({
  name: Yup.string()
    .required("Name is required")
    .max(50, "Maximun 50 Characters allowed"),
  description: Yup.string()
    .required("Description is required")
    .min(10, "Description should be atleast 10 characters long")
    .max(500, "Maximun 500 characters allowed")
})

const suggestedTags = [
  "Facts",
  "Historical Facts",
  "Science Facts",
  "Fun Facts",
  "Comedy",
  "Cultural Facts",
  "Psychology Facts",
  "Tech & Innovations"
]

export default function CreateCommunityModal({
  onCancel,
  community = null,
  onSuccess = () => {},
  refetch
}) {
  const navigate = useNavigate()
  const [addMedia] = useAddMediaMutation()
  const [profilePicture, setProfilePicture] = useState({})
  const [createCommunity, { isLoading: isCreatingCommunity }] =
    useCreateCommunityMutation()
  const { data: communitiesCategories = [] } = useGetCommunityCategoriesQuery()
  const categories = communitiesCategories.map(category => ({
    label: category.name,
    value: category.id
  }))
  const isEditMode = community

  const initialFormValues = isEditMode
    ? {
        name: community.name,
        visibility: community.visibility,
        description: community.description,
        keywords: community.keywords,
        category: community.category
      }
    : {
        name: "",
        visibility: "public",
        description: "",
        keywords: [],
        category: null
      }

  useEffect(() => {
    if (isEditMode && community.display_image) {
      setProfilePicture(community.display_image)
    }
  }, [community, isEditMode])

  const handleUpload = async ({ file }) => {
    const formData = new FormData()
    formData.append("file", file)
    try {
      const data = await addMedia(formData).unwrap()
      setProfilePicture(data)
    } catch (error) {
      openNotification({ type: "error", message: error?.message })
    }
  }
  const { values, errors, touched, handleSubmit, handleBlur, setValues } =
    useFormik({
      initialValues: initialFormValues,
      validationSchema: validationSchema,
      onSubmit: async values => {
        try {
          if (isEditMode) values.community_id = community.community_id
          if (profilePicture) values.display_image = profilePicture.object_key
          const response = await createCommunity(values).unwrap()
          openNotification({
            type: "success",
            message: `Community ${isEditMode ? "updated" : "created"} successfully`
          })
          if (refetch) refetch()
          if (!isEditMode) {
            navigate(`/community/${response.community_id}`)
          }
          onSuccess()
        } catch (error) {
          openNotification({
            type: "error",
            message: `Failed to ${isEditMode ? "update" : "create"} community`,
            description: error.errors || ""
          })
        }
      }
    })

  const onConfirm = () => {
    handleSubmit()
  }

  const currentKeywords = values?.keywords || []

  const filteredKeywords = suggestedTags.filter(
    keyword => !currentKeywords.includes(keyword)
  )

  return (
    <Modal
      title="Create New Community"
      centered
      open
      className="CustomModalWrapper"
      cancelText="Cancel"
      okText={isEditMode ? "Update" : "Save"}
      onCancel={onCancel}
      onOk={onConfirm}
      confirmLoading={isCreatingCommunity}
      okButtonProps={{ disabled: isCreatingCommunity }}
      width={500}
    >
      <div className="create-community-modal">
        <div className="edit-user-image">
          <Avatar
            size={74}
            src={profilePicture?.url}
            icon={!profilePicture?.url && <UserOutlined />}
            style={{ backgroundColor: "#3DC2DC" }}
          />
          <span className="edit-icon">
            <Upload
              customRequest={handleUpload}
              showUploadList={false}
              disabled={isCreatingCommunity}
              accept="image/*"
            >
              <Yellow28EditIcon />
            </Upload>
          </span>
        </div>
        <Flex gap={12} vertical>
          <CustomInput
            placeholder="Enter"
            label="Name"
            className="mb-3"
            value={values.name}
            type="text"
            onChange={e => setValues({ ...values, name: e.target.value })}
            errorMessage={touched["name"] && errors?.name}
            onBlur={handleBlur}
            inputProps={{
              status: touched["name"] && errors?.name ? "error" : undefined
            }}
          />
          <CustomSelect
            label="Choose Privacy"
            placeholder="Select"
            value={values.visibility}
            handleChange={value => setValues({ ...values, visibility: value })}
            options={[
              { label: "Public", value: "public" },
              { label: "Private", value: "private" }
            ]}
          />
          <CustomSelect
            label="Choose Category"
            placeholder="Select"
            value={values.category}
            handleChange={value => setValues({ ...values, category: value })}
            options={categories}
          />
          <Flex vertical>
            <label className={`Input_label`}>Tags</label>
            <Select
              name="keywords"
              mode="tags"
              value={currentKeywords}
              placeholder="Add tags"
              onChange={value =>
                setValues({
                  ...values,
                  keywords: value
                    .map(tag => tag.trim())
                    .filter(tag => tag !== "")
                })
              }
              onDeselect={value => {
                setValues({
                  ...values,
                  keywords: currentKeywords.filter(keyword => keyword !== value)
                })
              }}
              handleBlur={handleBlur}
            />
          </Flex>
          <div className="suggested-tags">
            <h6>Suggested Tags</h6>
            <Flex gap={8} wrap>
              {filteredKeywords.map(keyword => (
                <div
                  key={keyword}
                  className="skill"
                  onClick={() =>
                    setValues({
                      ...values,
                      keywords: [...currentKeywords, keyword]
                    })
                  }
                >
                  {keyword}
                </div>
              ))}
            </Flex>
          </div>
          <CustomTextArea
            placeholder="Text"
            label="Description"
            className="mb-3"
            value={values.description}
            onChange={e =>
              setValues({ ...values, description: e.target.value })
            }
            errorMessage={touched["description"] && errors?.description}
            onBlur={handleBlur}
            inputProps={{
              status:
                touched["description"] && errors?.description
                  ? "error"
                  : undefined
            }}
          />
        </Flex>
      </div>
    </Modal>
  )
}
