import { Button, Card, Flex, Typography,Spin } from "antd"
import { useState } from "react"
import Arrowup from "../../../assets/images/arrow.svg"
import { ReactComponent as EmployerIcon } from "../../../assets/common/employer.svg"
import { ReactComponent as WorkerIcon } from "../../../assets/common/worker.svg"
import "./styles.scss"

const { Title, Paragraph } = Typography

const roles = [
  {
    name: "social_media_user",
    title: "Social Media",
    description:
      "Engage with a vibrant community and connect across platforms. Share, discover, and view posts from various networks—all in one place."
  },
  {
    name: "flex-time",
    title: "Flextime Force",
    description:
      "Find flexible job opportunities or hire remote talent. Our platform connects workers and employers for seamless, adaptable work solutions.",
    subRoles: [
      {
        name: "worker",
        title: "Join as worker",
        description: "I am a freelancer looking for flexible job opportunities",
        icon: WorkerIcon
      },
      {
        name: "employer",
        title: "Join as an Employer",
        description:
          "I am a client looking for talented individuals to work on my project.",
        icon: EmployerIcon
      }
    ]
  },
  {
    name: "donor",
    title: "Donate to Non-Profits",
    description:
      "Support your favorite causes by donating directly to nonprofits through our platform. Make a difference with just a few clicks."
  }
]

export default function RoleSelection({ mainRole = null, onChangeMainRole }) {
 
  return (
    <div className="roleSelection">
      {!mainRole && (
        <>
          <Title className="roleSelectionTitle">
            Which Service Would You Like to Continue With?
          </Title>
          <Paragraph className="roleSelectionHelpertext">
            Don’t worry, you can always switch services later.
          </Paragraph>
          <Flex gap="32px" className="mt-5" justify="center" wrap>
            {roles.map(role => (
              <Card
                className="card-service"
                onClick={() => onChangeMainRole(role.name)}
              >
                <Title className="card-headings" level={4}>
                  {role.title}
                </Title>

                <div className="card-text-box">
                  <Paragraph className="paragraph-text">
                    {role.description}
                  </Paragraph>
                </div>
              </Card>
            ))}
          </Flex>
        </>
      )}
      {mainRole === "flex-time" && (
        <Flex gap="16px">
          {roles[1].subRoles.map(({ title, name, description, icon: Icon }) => (
            <Card
              className="card-service flex-time-services"
              onClick={() => onChangeMainRole(name)}
            >
              <Icon />
              <Title className="card-headings" level={4}>
                {title}
              </Title>
              <div className="card-text-box">
                <Paragraph className="paragraph-text">{description}</Paragraph>
              </div>
            </Card>
          ))}
        </Flex>
      )}
    </div>
  )
}
