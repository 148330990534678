import React, { useState } from "react"
import {
  Button,
  Typography,
  Flex,
  Space,
  Table,
  Tag,
  Popover,
  Input
} from "antd"

import {
  ActiveIcon,
  BubbleChatIcon,
  CancelledIcon,
  CompletedIcon,
  EyeIcon,
  FilterButtonIcon,
  PendingIcon
} from "../../assets/rawSvgs"

import {
  useGetFlexTimeChatMutation,
  useGetJobRequestsQuery
} from "../../api/apiSlices/flexTimeApiSlice"

import useRouterNavigation from "../../hooks/useNavigate"

import { Routes } from "../../constants/routes"

import FilterComponent from "./FilterComponent"
import { formatToYYYYMMDD } from "../../util/ConvertDateTime"
import { useDebounce } from "../../hooks/useDebounce"
import { getUser } from "../../api/auth"
import { openNotification } from "../../util/openNotifications"

const { Title, Text } = Typography
const { Search } = Input

const AllWorkerJobs = () => {
  const [filters, setFilters] = useState({
    minHourlyRate: null,
    maxHourlyRate: null,
    startDate: null,
    endDate: null,
    status: null
  })
  const [queryFilters, setQueryFilters] = useState({
    budget_max: null,
    budget_min: null,
    start_date: null,
    end_date: null,
    status: null
  })

  const [search, setSearch] = useState("")

  const { navigateTo } = useRouterNavigation()

  const currentUser = getUser()

  const debouncedSearch = useDebounce(search, 500)

  const {
    data: jobRequestsList,
    isLoading: isJobRequestListLoading,
    refetch: refetchJobRequestList
  } = useGetJobRequestsQuery(
    { ...queryFilters, title: debouncedSearch },
    {
      refetchOnMountOrArgChange: true
    }
  )

  const [getFlexTimeChat, { isLoading: isGetFlexTimeChatLoading }] =
    useGetFlexTimeChatMutation()

  const statusStyles = {
    pending: {
      icon: <PendingIcon />,
      borderColor: "#FFD591",
      textColor: "#D46B08",
      backgroundColor: "#FFF7E6",
      label: "Pending"
    },
    active: {
      icon: <ActiveIcon />,
      borderColor: "#91CAFF",
      textColor: "#3DC2DC",
      backgroundColor: "#E6F4FF",
      label: "Active"
    },
    complete: {
      icon: <CompletedIcon />,
      borderColor: "#B7EB8F",
      textColor: "#52C41A",
      backgroundColor: "#F6FFED",
      label: "Completed"
    },
    cancelled: {
      icon: <CancelledIcon />,
      borderColor: "#FFA39E",
      textColor: "#F5222D",
      backgroundColor: "#FFF1F0",
      label: "Cancelled"
    }
  }

  const StatusTag = ({ status }) => {
    const { icon, borderColor, textColor, label, backgroundColor } =
      statusStyles[status] || {}

    return (
      <Tag
        style={{
          borderColor: borderColor,
          color: textColor,
          backgroundColor: backgroundColor,
          borderStyle: "solid",
          borderWidth: "1px"
        }}
      >
        <Space>
          {icon}
          <span>{label}</span>
        </Space>
      </Tag>
    )
  }

  const onFilterApply = () => {
    setQueryFilters({
      hourly_rate_min: filters.minHourlyRate,
      hourly_rate_max: filters.maxHourlyRate,
      start_date: formatToYYYYMMDD(filters.startDate),
      end_date: formatToYYYYMMDD(filters.endDate),
      status: filters.status
    })
  }

  const handleChat = async data => {
    const payload = {
      recipient_role: currentUser?.role,
      recipient_id: currentUser?.id,
      job_id: data?.id
    }
    try {
      await getFlexTimeChat(payload).unwrap()
      navigateTo(
        `${Routes.CHAT}?channel_id=J${data?.id}-Admin-W${currentUser?.id}`
      )
    } catch (error) {
      openNotification({
        type: "error",
        message:
          error?.message ||
          error?.errors ||
          "Unexpected error occurred. Please try again."
      })
    }
  }

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      
      key: "title",
      render: text => <a>{text}</a>
    },
    {
      title: "Location",
      dataIndex: "location",
      
      key: "location"
    },
    {
      title: "Employer",
      key: "employer",
    
      render: (_, record) => <Text>{record?.employer?.name ?? "--"}</Text>
    },
    {
      title: "Status",
      key: "status",
    
      dataIndex: "status",
      render: (_, render) => StatusTag(render)
    },
    {
      title: "Duration",
      key: "duration",
    
      dataIndex: "duration",
      render: (_, record) => (
        <Text>
          {record?.start_date} to {record?.end_date}
        </Text>
      )
    },
    {
      title: "Hourly Rate",
      key: "hourly_rate",
      dataIndex: "hourly_rate",
      
      render: (_, record) => (
        <Text>
          ${record?.budget_min} - ${record?.budget_max}
        </Text>
      )
    },
    {
      title: "Admin Chat",
      key: "admin_chat",
    
      dataIndex: "admin_chat",
      render: (_, record) => (
        <Button
          icon={<BubbleChatIcon />}
          disabled={isGetFlexTimeChatLoading}
          onClick={() => handleChat(record)}
        />
      )
    },
    {
      title: "Job Chat",
      key: "job_chat",
      dataIndex: "job_chat",
      
      render: (_, record) => (
        <Button
          disabled={!record?.initiate_chat}
          icon={<BubbleChatIcon />}
          onClick={() =>
            navigateTo(`${Routes.CHAT}?channel_id=${record?.channel_id}`)
          }
        />
      )
    },
    {
      title: "Actions",
      key: "action",
      align: "center",
      render: (_, record) => (
        <>
          <div
            onClick={() => {
              console.log("id", record?.id)
              if (record?.id) {
                navigateTo(`${Routes.JOB_DETAIL.replace(":id", record?.id)}`)
              } else {
                console.error("Job ID is missing")
              }
            }}
          >
            <Flex
              align="flex-start"
              justify="center"
              // onClick={() =>
              //   //navigateTo(Routes.JOB_DETAIL.replace(":id", record?.id))
              // }
              // onClick={() => {
              //   console.log("id",record?.id);
              //   if (record?.id) {
              //     navigateTo(Routes.JOB_DETAIL);
              //   } else {
              //     console.error("Job ID is missing");
              //   }
              // }}
            >
              <EyeIcon />
            </Flex>
          </div>
        </>
      )
    }
  ]

  return (
    <div>
      <Flex justify="space-between" className="mb-12">
        <Title level={4} className="mb-1" aria-label="name">
          My Gigs
        </Title>
        <Flex gap={12}>
          <Search
            placeholder="Search"
            // onSearch={() => console.log("searching")}
            className="SearchWrapper"
            allowClear
            value={search}
            onChange={e => setSearch(e.target.value)}
            style={{
              width: 360
            }}
          />
          <Popover
            content={
              <FilterComponent
                filters={filters}
                setFilters={setFilters}
                onFilterApply={onFilterApply}
                setQueryFilters={setQueryFilters}
                isLoading={isJobRequestListLoading}
              />
            }
            trigger="click"
            style={{ width: "500px" }}
            overlayInnerStyle={{ padding: "0" }}
          >
            <Button icon={<FilterButtonIcon />}>Filters</Button>
          </Popover>
        </Flex>
      </Flex>
      <Table
        columns={columns}
        dataSource={jobRequestsList}
        loading={isJobRequestListLoading}
      />
    </div>
  )
}

export default AllWorkerJobs
