import { apiSlice } from "../apiSlice"

import {
  DELETE_JOB_REQUEST,
  EDIT_WORKER_PROFILE,
  FLEXTIME_CHAT,
  GET_COUNTRIES,
  GET_JOB_REQUEST,
  GET_JOB_REQUEST_DETAIL,
  GET_WORKER_PROFILE,
  UPDATE_JOB_REQUEST,
  UPDATE_PROFILE_DATA
} from "../../constants/endpoints"

export const flexTimeApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getWorkerProfile: builder.query({
      query: token => {
        const queryParams = token ? `?token=${token}` : ""
        return {
          url: `${GET_WORKER_PROFILE}${queryParams}`
        }
      },
      keepUnusedDataFor: 0
    }),
    getCountries: builder.query({
      query: () => ({
        url: GET_COUNTRIES
      }),
      keepUnusedDataFor: 0
    }),
    editWorkerProfile: builder.mutation({
      query: body => ({
        url: EDIT_WORKER_PROFILE,
        method: "POST",
        body: body
      })
    }),
    updateProfileData: builder.mutation({
      query: body => ({
        url: UPDATE_PROFILE_DATA,
        method: "PATCH",
        body: body
      })
    }),
    getJobRequests: builder.query({
      query: params => ({
        url: GET_JOB_REQUEST,
        params
      }),
      keepUnusedDataFor: 0
    }),
    deleteJobRequest: builder.mutation({
      query: id => ({
        url: DELETE_JOB_REQUEST.replace(":id", id),
        method: "DELETE"
      })
    }),
    updateJobRequest: builder.mutation({
      query: body => ({
        url: UPDATE_JOB_REQUEST.replace(":id", body?.id),
        method: "PATCH",
        body: { status: body?.status }
      })
    }),
    getJobRequestDetail: builder.query({
      query: id => ({
        url: GET_JOB_REQUEST_DETAIL.replace(":id", id)
      })
    }),
    getFlexTimeChat: builder.mutation({
      query: body => ({
        url: FLEXTIME_CHAT,
        method: "POST",
        body: body
      })
    })
  }),
  overrideExisting: false
})

export const {
  useGetWorkerProfileQuery,
  useGetCountriesQuery,
  useEditWorkerProfileMutation,
  useGetJobRequestsQuery,
  useDeleteJobRequestMutation,
  useUpdateJobRequestMutation,
  useGetJobRequestDetailQuery,
  useGetFlexTimeChatMutation,
  useUpdateProfileDataMutation
} = flexTimeApiSlice
