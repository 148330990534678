import React from "react"
import { Col, Flex, Spin } from "antd"
import { useNavigate, useParams } from "react-router-dom"
import { useFeed } from "../../hooks/useFeed"
import PostCard from "../social-media/components/postCard/PostCard"
import { ArrowLeftOutlined } from "@ant-design/icons"

const Post = () => {
  let { id: postId } = useParams()
  const navigate = useNavigate()
  const { handleComment, isAddingComment, loading, posts, toggleLike } =
    useFeed({ feedId: postId, feedType: "post" })

  const feed = posts[0]?.data

  const goBack = () => navigate(-1)
  return (
    <div className="socialMediaScreen">
      <Spin spinning={loading}>
        <Flex gap="48px">
          <Col className="gutter-row" span={4}>
            <Flex justify="flex-end" style={{ marginTop: "8px" }}>
              <ArrowLeftOutlined
                style={{ cursor: "pointer" }}
                onClick={goBack}
              />
            </Flex>
          </Col>
          <Col className="gutter-row" span={16}>
            <div className="socialMediaScroll">
              {!loading && feed && (
                <PostCard
                  index={feed?.post_id}
                  attachments={feed?.attachments}
                  author={feed?.author}
                  comments={feed?.comments}
                  comments_count={feed?.comments_count}
                  comments_enabled={feed?.comments_enabled}
                  content={feed?.content}
                  created_at={feed?.created_at}
                  likes_count={feed?.likes_count}
                  post_id={feed?.post_id}
                  likes_enabled={feed?.likes_enabled}
                  user_has_liked={feed?.user_has_liked}
                  likes={feed?.likes}
                  togglePostLike={toggleLike}
                  addCommentToPost={handleComment}
                  isAddCommentToPostLoading={isAddingComment}
                  feed={feed}
                  key={feed?.post_id}
                  ref={null}
                />
              )}
            </div>
          </Col>
          <Col className="gutter-row" span={4}></Col>
        </Flex>
      </Spin>
    </div>
  )
}

export default Post
