import React from "react"
import {
  Layout,
  Flex,
  Row,
  Col,
  Button,
  Card,
  Collapse,
  Typography,
  Divider
} from "antd"

import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons"

import BannerSmall from "../../assets/images/banner-small.png"
import Arrowup from "../../assets/images/arrow.svg"
import Services1 from "../../assets/images/services-4.png"
import Services2 from "../../assets/images/services-5.png"
import Services3 from "../../assets/images/services-6.png"
import CardOne from "../../assets/images/img-card-1.svg"
import CardTwo from "../../assets/images/img-card-2.svg"
import CardThree from "../../assets/images/img-card-3.svg"
import { Agp , Twofingers,Twitter, Facebook, Github, Ball, Linkden } from "../../assets/rawSvgs"
import { useGetFAQQuery } from "../../api/apiSlices/userApiSlice"

import "./landing.scss"
import { Link, useNavigate } from "react-router-dom"
import { Routes } from "../../constants/routes"
import { LinkIcon } from "stream-chat-react"

const { Title, Paragraph ,Text } = Typography




const text = (
  <p className="accordion-body-text">
    "All Good People" offers a range of services including Flextime Work for
    flexible job opportunities, a Social Media platform for community
    engagement, an E-commerce marketplace with a donation module, a Loan
    Platform for easy loan applications, and a direct donation feature to
    support nonprofits.
  </p>
)

const items = [
  {
    key: "1",
    label: 'What services does "All Good People" offer?',
    children: text
  },
  {
    key: "2",
    label: "How do I apply for a loan on your platform?",
    children: text
  },
  {
    key: "3",
    label: "How can I donate to nonprofits through your platform?",
    children: text
  }
]
const LandingPage = () => {
  const customExpandIcon = ({ isActive }) =>
    isActive ? <MinusCircleOutlined /> : <PlusCircleOutlined />
  const { data: faqData, isLoading, isError } = useGetFAQQuery();
  console.log("FAQ",faqData);
  const navigate = useNavigate()
  return (
    <>
      <section className="banner-main">
        <div className="container-layout">
          <Layout className="bg-color">
            <Row gutter={[16, 16]}>


            
              <Col span={18}>
                <div className="banner-left-box">
                
                  <div className="text-position ">
                    <Title className="heading-banner width-1" level={2}>
                      Empowering Connections{" "}
                    </Title>
                    <Title className="heading-banner width-2" level={2}>
                      & Opportunities
                    </Title>
                    <Title className="heading-banner width-3" level={2}>
                      for All
                    </Title>
                  </div>

                  <div className="paragraph-box">
                    <Paragraph className="paragraph-text">
                      Explore diverse services tailored for a better tomorrow.
                      Flexible Work, Social Connection, and Purposeful
                      Shopping—All in One Place.
                    </Paragraph>
                    <Flex gap="small">
                      <Button
                        className="primary-button"
                        type="primary"
                        onClick={() => navigate(Routes.REGISTER)}
                      >
                        Get Started
                      </Button>
                      <Button className="secondary-button">Learn more</Button>
                    </Flex>
                  </div>
                
                </div>
              </Col>


              <Col span={6}>
                <div className="img-banner-small mb-3">
                  <img src={BannerSmall} className="img-fluid" alt="img" />
                </div>
                <Flex gap="small">
                  <Paragraph className="text-small-img">
                    Support your favourite causes by donating directly to
                    nonprofits through our platform. Make a difference with just
                    a few clicks.
                  </Paragraph>
                  <Button
                    className="rounded-btn"
                    type="primary"
                    shape="circle"
                    onClick={() => navigate(`${Routes.REGISTER}?role=donor`)}
                  >
                    <img src={Arrowup} className="img-fluid" alt="img" />
                  </Button>
                </Flex>
              </Col>

           

            </Row>
          </Layout>
        </div>
      </section>
      <section className="services-section">
        <div className="container-main">
          <Title className="main-section-heading" level={3}>
            Our Services
          </Title>
          <div className="services-text-box">
            <Paragraph className="paragraph-text">
              Explore diverse services tailored for a better tomorrow. Flexible
              Work, Social Connection, and Purposeful Shopping—All in One Place.
            </Paragraph>
          </div>
          <Row className="services-cards-container" gutter={[32, 32]}>
            <Col span={12}>
              <Row gutter={[0, 32]}>
                <Card className="card-services">
                  {/* <div className="img-icons">
                <img className='img-fluid' src={CardOne} alt="img" />
              </div> */}
                  <Title className="card-headings" level={4}>
                    Donate to Non-Profits
                  </Title>
                  <Flex gap="small">
                    <div className="card-text-box">
                      <Paragraph className="paragraph-text">
                        Support your favorite causes by donating directly to
                        nonprofits through our platform. Make a difference with
                        just a few clicks.
                      </Paragraph>
                    </div>
                    <Button
                      className="rounded-btn"
                      type="primary"
                      shape="circle"
                      onClick={() => navigate(`${Routes.REGISTER}?role=donor`)}
                    >
                      <img src={Arrowup} className="img-fluid" alt="img" />
                    </Button>
                  </Flex>
                </Card>
                <Card className="card-services blue-services">
                  <Title className="card-headings" level={4}>
                    Loan Taking Platform
                  </Title>
                  <Flex gap="small">
                    <div className="card-text-box">
                      <Paragraph className="paragraph-text">
                        Easily apply for loans through our app. Submit your
                        application, and we’ll handle the rest.
                      </Paragraph>
                    </div>
                    <Button
                      className="rounded-btn"
                      type="primary"
                      shape="circle"
                      onClick={() => navigate(Routes.REGISTER)}
                    >
                      <img src={Arrowup} className="img-fluid" alt="img" />
                    </Button>
                  </Flex>
                </Card>
              </Row>
            </Col>
            <Col span={12}>
              <Card className="card-services purple-services">
                <div className="img-icons">
                  <img className="img-fluid" src={Services1} alt="img" />
                </div>
                <div className="text-body-card">
                  <Title className="card-headings" level={4}>
                    Flextime Force
                  </Title>
                  <Flex gap="small">
                    <div className="card-text-box">
                      <Paragraph className="paragraph-text">
                        Find flexible job opportunities or hire remote talent.
                        Our platform connects workers and employers for
                        seamless, adaptable work solutions.
                      </Paragraph>
                    </div>
                    <Button
                      className="rounded-btn"
                      type="primary"
                      shape="circle"
                      onClick={() =>
                        navigate(`${Routes.REGISTER}?role=flex-time`)
                      }
                    >
                      <img src={Arrowup} className="img-fluid" alt="img" />
                    </Button>
                  </Flex>
                </div>
              </Card>
            </Col>
            <Col span={12}>
              <Card className="card-services pink-services">
                <div className="img-icons">
                  <img className="img-fluid" src={Services2} alt="img" />
                </div>
                <div className="text-body-card">
                  <Title className="card-headings" level={4}>
                    Social Media
                  </Title>
                  <Flex gap="small">
                    <div className="card-text-box">
                      <Paragraph className="paragraph-text">
                        Engage with a vibrant community and connect across
                        platforms. Share, discover, and view posts from various
                        networks—all in one place.
                      </Paragraph>
                    </div>
                    <Button
                      className="rounded-btn"
                      type="primary"
                      shape="circle"
                      onClick={() =>
                        navigate(`${Routes.REGISTER}?role=social_media_user`)
                      }
                    >
                      <img src={Arrowup} className="img-fluid" alt="img" />
                    </Button>
                  </Flex>
                </div>
              </Card>
            </Col>
            <Col span={12}>
              <Card className="card-services green-services">
                <div className="img-icons">
                  <img className="img-fluid" src={Services3} alt="img" />
                </div>
                <div className="text-body-card">
                  <Title className="card-headings" level={4}>
                  Marketplace
                  </Title>
                  <Flex gap="small">
                    <div className="card-text-box">
                      <Paragraph className="paragraph-text">
                      Shop and sell with purpose on our platform, where every purchase supports nonprofits. 
                      Experience a marketplace where your transactions make a positive impact.
                      </Paragraph>
                    </div>
                    <Button
                      className="rounded-btn"
                      type="primary"
                      shape="circle"
                      onClick={() =>
                        navigate(`${Routes.REGISTER}?role=social_media_user`)
                      }
                    >
                      <img src={Arrowup} className="img-fluid" alt="img" />
                    </Button>
                  </Flex>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </section>
      <section className="trust-section">
        <div className="container-main">
          <Title className="main-section-heading" level={3}>
            Why can you trust us?
          </Title>
          <div className="services-text-box">
            <Paragraph className="paragraph-text">
              Discover the Reasons We’re a Trusted Choice
            </Paragraph>
          </div>
          <Row className="trust-padding" gutter={[16, 16]}>
            <Col className="card-container" span={8}>
              <Card className="card-main">
                <div className="img-icons">
                  <img className="img-fluid" src={CardOne} alt="img" />
                </div>
                <Title className="card-headings" level={4}>
                  Commitment to Security
                </Title>
                <div className="card-text-box">
                  <Paragraph className="paragraph-text">
                    We prioritise your safety with robust security measures,
                    ensuring your data and transactions are always protected.
                  </Paragraph>
                </div>
              </Card>
            </Col>
            <Col className="card-container" span={8}>
              <Card className="card-main">
                <div className="img-icons">
                  <img className="img-fluid" src={CardTwo} alt="img" />
                </div>
                <Title className="card-headings" level={4}>
                  Transparency
                </Title>
                <div className="card-text-box">
                  <Paragraph className="paragraph-text">
                    Our operations and policies are straightforward, ensuring
                    you know exactly how we operate and handle your information.
                  </Paragraph>
                </div>
              </Card>
            </Col>
            <Col className="card-container" span={8}>
              <Card className="card-main">
                <div className="img-icons">
                  <img className="img-fluid" src={CardThree} alt="img" />
                </div>
                <Title className="card-headings" level={4}>
                  Reliability
                </Title>
                <div className="card-text-box">
                  <Paragraph className="paragraph-text">
                    Our platform's consistent performance makes us your trusted
                    choice for work, connections, and secure transactions.
                  </Paragraph>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </section>
      {/* <section className="faq-section">
        <div className="container-main">
          <Row className="trust-padding" gutter={[16, 64]}>
            <Col span={12}>
              <Title className="main-section-heading text-start" level={3}>
                Frequently asked questions
              </Title>
              <div className="services-text-box text-start">
                <Paragraph className="paragraph-text text-start">
                  Everything you need to know about the “All Good People” app.
                </Paragraph>
              </div>
            </Col>
            <Col span={12}>
              <div className="accordion-main">
                <Collapse
                  items={items}
                  bordered={false}
                  defaultActiveKey={["1"]}
                  expandIcon={customExpandIcon}
                  accordion
                  expandIconPosition="end" // Position icons on the right
                />
              </div>
            </Col>
          </Row>
        </div>
      </section> */}

<section className="faq-section">
      <div className="container-main">
        <Row className="trust-padding" gutter={[16, 64]}>
          <Col span={12}>
            <Title className="main-section-heading text-start" level={3}>
              Frequently asked questions
            </Title>
            <div className="services-text-box text-start">
              <Paragraph className="paragraph-text text-start">
                Everything you need to know about the “All Good People” app.
              </Paragraph>
            </div>
          </Col>
          <Col span={12}>
            <div className="accordion-main">
              <Collapse
                bordered={false}
                defaultActiveKey={["1"]}
                expandIcon={customExpandIcon}
                accordion
                expandIconPosition="end"
              >
               
                {faqData?.faqs?.map((faq) => (
                  <Collapse.Panel header={faq.question} key={faq.id}>
                    <p>{faq.answer}</p>
                  </Collapse.Panel>
                ))}
              </Collapse>
            </div>
          </Col>
        </Row>
      </div>
    </section>
      {/* <Button onClick={getFAQ()}>{console.log("FAQ", getFAQ)}</Button> */}
      <section style={{ backgroundColor: "#131744" , width:'100%',height:'380px' }}
      >
        <div>
        <div style={{paddingTop:'64px',paddingBottom:'64px' , paddingRight:'64px' ,paddingLeft:'64px'}}>
             <Flex vertical  gap="32px">
             
             
              <div>
                <Flex gap="1350px">
                
                
                <div>
                 <Agp/>
                 </div>


                 <div>  
                  <Flex gap="24px">
                 <Twitter/>
                 <Linkden/>
                 <Facebook/>
                 <Github/>
                 <Twofingers/>
                 <Ball/>
                 </Flex>
                
                  </div>


                  </Flex>
                 </div>


                  <div>
                    
                    <Flex gap="32px" >
                    <Text style={{color:'#E4E7EC'}}>Home</Text>
                    <Text style={{color:'#E4E7EC'}}>Flextime Force</Text>
                     <Text style={{color:'#E4E7EC'}}>Social Platform</Text> 
                     <Text style={{color:'#E4E7EC'}}>E-commerce</Text> 
                     <Text style={{color:'#E4E7EC'}}>Donate</Text> 
                     <Text style={{color:'#E4E7EC'}}>Take Loan</Text>
                     <Text style={{color:'#E4E7EC'}}>Terms and Conditions</Text>
                     </Flex>
                  </div>
             </Flex>

        </div>
        </div>

       
       
        <Divider
        style={{
          borderColor:"#475467",
          height: "0px",
          margin: "0px", 
          width: "0px", 
        }}
      />

        <div style={{paddingTop:'48px'}}>
          <Flex align="center" justify="center">
           <div><Text style={{fontFamily:'Inter',fontSize:'16px',fontWeight:'400',color:'#98A2B3'}}>© 2024 All Good People. All rights reserved.</Text></div>
           </Flex>
        </div>
      
      </section>
    </>
  )
}

export default LandingPage
