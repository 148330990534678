import { SearchOutlined, TagOutlined } from "@ant-design/icons"
import { Drawer, Empty, Flex, Input, Select, Spin, Tabs } from "antd"
import { useDebounce } from "../../hooks/useDebounce"
import { useState } from "react"
import { useSearchCommunitiesQuery } from "../../api/apiSlices/communityApiSlice"
import CommunityListItem from "../communities-manage-banner/components/CommunityListItem"
import UserListItem from "../communities-manage-banner/components/UserListItem"
import { useSearchAllUsersQuery } from "../../api/apiSlices/socialMediaApiSlice"
import "./TopBar.scss"

export default function SearchBar({ open, onClose }) {
  const [search, setSearch] = useState("")
  const [activeTab, setActiveTab] = useState("communities")
  const [keywordSearch, setKeywordSearch] = useState([])

  const debouncedSearchTerm = useDebounce(search, 500)

  const { data: { results } = { results: [] }, isFetching } =
    useSearchCommunitiesQuery(
      {
        searchText: debouncedSearchTerm + keywordSearch.join()
      },
      { refetchOnMountOrArgChange: true }
    )

  const {
    data: { results: userResults } = { results: [] },
    isFetching: isFetchingUsers
  } = useSearchAllUsersQuery(
    {
      searchText: debouncedSearchTerm
    },
    { refetchOnMountOrArgChange: true }
  )

  const items = [
    {
      key: "people",
      label: "People"
    },
    {
      key: "communities",
      label: "Communities"
    }
  ]
  return (
    <Drawer
      open={open}
      placement="right"
      onClose={onClose}
      title=""
      width={400}
      extra={
        <Flex vertical gap={8}>
          <Input
            value={search}
            placeholder="Search"
            prefix={<SearchOutlined />}
            onChange={event => setSearch(event.target.value)}
          />
          {activeTab === "communities" && (
            <Select
              prefix={<TagOutlined />}
              mode="tags"
              value={keywordSearch}
              placeholder="Search by tags"
              onChange={value => setKeywordSearch(value)}
              onDeselect={value => {
                setKeywordSearch(
                  keywordSearch.filter(keyword => keyword !== value)
                )
              }}
              open={false}
            />
          )}
        </Flex>
      }
      className="searchBar"
    >
      <Flex vertical>
        <Tabs
          activeKey={activeTab}
          onChange={activeKey => {
            setActiveTab(activeKey)
            setSearch("")
            setKeywordSearch([])
          }}
          items={items}
        />
        {activeTab === "communities" && (
          <Spin spinning={isFetching}>
            {search.length === 0 && keywordSearch.length === 0 && (
              <Empty description="Get started by searching" />
            )}
            <Flex vertical gap={16} style={{ overflowY: "auto" }}>
              {(search.length !== 0 || keywordSearch.length !== 0) &&
                results?.length === 0 && (
                  <Flex
                    justify="center"
                    align="center"
                    vertical
                    style={{ height: "100%", marginTop: "100px" }}
                  >
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="No results found against this search"
                    />
                  </Flex>
                )}
              {results?.map(community => (
                <CommunityListItem
                  key={community.community_id}
                  id={community.community_id}
                  name={community.name}
                  membersCount={community.member_count}
                  displayImage={community?.display_image?.url}
                  visibility={community.visibility}
                />
              ))}
            </Flex>
          </Spin>
        )}
        {activeTab === "people" && (
          <Spin spinning={isFetchingUsers}>
            {search.length === 0 && (
              <Empty description="Get started by searching" />
            )}  
            <Flex vertical gap={16} style={{ overflowY: "auto" }}>
              {search.length !== 0 && userResults?.length === 0 && (
                <Flex
                  justify="center"
                  align="center"
                  vertical
                  style={{ height: "100%", marginTop: "100px" }}
                >
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No results found against this search"
                  />
                </Flex>
              )}
              {search.length !== 0 && userResults?.map(user => (
                <UserListItem
                  key={user.id}
                  id={user.id}
                  name={user.name}
                  displayImage={user?.profile?.profile_picture?.url}
                />
              ))}
            </Flex>
          </Spin>
        )}
      </Flex>
    </Drawer>
  )
}
