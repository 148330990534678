import { apiSlice } from "../apiSlice"

import {
  DONATIONS_LIST,
  NON_PROFIT_ORGS,
  NON_RECURRING_DONATIONS_LIST,
  REMOVE_PAYMENT_DONATION
} from "../../constants/endpoints"

export const donationApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getNonProfitOrgs: builder.query({
      query: () => ({
        url: NON_PROFIT_ORGS
      })
    }),
    getUserDonations: builder.query({
      query: () => ({
        url: DONATIONS_LIST
      })
    }),
    removePayment: builder.mutation({
      query: body => ({
        url: REMOVE_PAYMENT_DONATION,
        method: "POST",
        body: body
      })
    }),
    getUserNonRecurringDonations: builder.query({
      query: () => ({
        url: NON_RECURRING_DONATIONS_LIST
      })
    })
  }),
  overrideExisting: false
})

export const {
  useGetNonProfitOrgsQuery,
  useGetUserDonationsQuery,
  useRemovePaymentMutation,
  useGetUserNonRecurringDonationsQuery
} = donationApiSlice
