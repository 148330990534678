import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Checkbox, DatePicker, Flex, Modal, Radio, Spin } from "antd"

import "./styles.scss"
import CustomInput from "../../../components/customInput/CustomInput"
import CustomTextArea from "../../../components/customInput/CustomTextArea"
import { useFormik } from "formik"
import * as Yup from "yup"
import DayJs from "dayjs"
import { useUpdateWorkerExperienceMutation } from "../../../api/apiSlices/workerApiSlice"
import { openNotification } from "../../../util/openNotifications"
import { setOnboardingStatus } from "./onboardingStatus"

const options = [
  { label: "Full Time", value: "full_time" },
  { label: "Part Time", value: "part_time" }
  // { label: "Remote", value: "remote" }
]
export const validationSchema = Yup.object({
  job_title: Yup.string().required("Job title is required"),
  company_name: Yup.string().required("Company name is required"),
  start_date: Yup.date().required("Start date is required"),
  end_date: Yup.date()
    .when("currently_working", {
      is: false,
      then: Yup.date()
        .required("End date is required")
        .test(
          "end-date-after-start-date",
          "End date cannot be earlier than the start date",
          function (value) {
            const { start_date } = this.parent; 
            return !start_date || !value || new Date(value) >= new Date(start_date);
          }
        ),
      otherwise: Yup.date().notRequired()
    }),
});


export default function ExperienceInputModal({ initialData = null, onClose }) {
  const initialState = initialData
    ? {
        ...initialData,
        end_date: initialData.end_date ? initialData.end_date : ""
      }
    : {
        job_title: "",
        company_name: "",
        start_date: "",
        end_date: "",
        location: "",
        description: "",
        currently_working: false,
        job_type: "full_time"
      }

  const isEditMode = initialData?.id

  const [addWorkerExperience, { isLoading }] =
    useUpdateWorkerExperienceMutation()

  const { values, errors, touched, handleSubmit, handleBlur, setValues } =
    useFormik({
      initialValues: initialState,
      validationSchema: validationSchema,
      onSubmit: values => {
        const data = {
          ...values,
          end_date: values.currently_working ? null : values.end_date,
          lat: 0,
          long: 0
        }
        setOnboardingStatus("step2", true);
        addWorkerExperience(data)
          .unwrap()
          .then(() => {
            onClose(true)
          })
          .catch(error => {
            openNotification({ type: "error", message: error?.message })
          })
         
      }
    })

  return (
    <Modal
      title="Add Experience"
      icon={<ExclamationCircleOutlined />}
      open
      cancelText="Cancel"
      okText={isEditMode ? "Edit" : "Add Experience"}
      onCancel={onClose}
      onOk={handleSubmit}
      okButtonProps={{ disabled: isLoading }}
      confirmLoading={isLoading}
      width="660px"
    >
      <Spin spinning={false}>
        <CustomInput
          name="job_title"
          label="Job title"
          inputwd="w-100 mb-3"
          placeholder="Ex. Software Developer"
          className="mb-3"
          required
          value={values?.job_title}
          onChange={e => setValues({ ...values, job_title: e.target.value })}
          errorMessage={touched["job_title"] && errors?.job_title}
          onBlur={handleBlur}
          inputProps={{
            status:
              touched["job_title"] && errors?.job_title ? "error" : undefined
          }}
        />
        <CustomInput
          name="company_name"
          label="Company"
          inputwd="w-100 mb-3"
          placeholder="Ex. Microsoft"
          className="mb-3"
          required
          value={values?.company_name}
          onChange={e => setValues({ ...values, company_name: e.target.value })}
          errorMessage={touched["company_name"] && errors?.company_name}
          onBlur={handleBlur}
          inputProps={{
            status:
              touched["company_name"] && errors?.company_name
                ? "error"
                : undefined
          }}
        />
        <Radio.Group
          name="job_type"
          options={options}
          onChange={event =>
            setValues({ ...values, job_type: event.target.value })
          }
          value={values.job_type}
          optionType="button"
          buttonStyle="solid"
        />
        <CustomInput
          name="location"
          label="Location"
          inputwd="w-100 mb-3 mt-3"
          placeholder="Ex. London"
          className="mb-3"
          value={values?.location}
          onChange={e => setValues({ ...values, location: e.target.value })}
        />

        <Checkbox
          onChange={event =>
            setValues({ ...values, currently_working: event.target.checked })
          }
          checked={values.currently_working}
          className="mb-2"
          name="currently_working"
        >
          I am currently working in this role
        </Checkbox>

        <Flex gap="16px">
          <Flex vertical>
            <label className={`Input_label`}>
              Start date<span style={{ color: "red" }}>*</span>
            </label>
            <DatePicker
              id="start_date"
              name="start_date"
              onChange={(_, dateString) =>
                setValues({ ...values, start_date: dateString })
              }
              value={values.start_date ? DayJs(values.start_date) : ""}
              onBlur={handleBlur}
              status={
                touched["start_date"] && errors?.start_date ? "error" : ""
              }
            />
            {touched["start_date"] && errors?.start_date && (
              <p className="Input_errorMessage">{errors?.start_date}</p>
            )}
          </Flex>
          {!values.currently_working && (
            <Flex vertical>
              <label className={`Input_label`}>
                End date<span style={{ color: "red" }}>*</span>
              </label>
              <DatePicker
                id="end_date"
                name="end_date"
                onChange={(_, dateString) =>
                  setValues({ ...values, end_date: dateString })
                }
                value={values.end_date ? DayJs(values.end_date) : ""}
                status={touched["end_date"] && errors?.end_date ? "error" : ""}
              />
              {touched["end_date"] && errors?.end_date && (
                <p className="Input_errorMessage">{errors?.end_date}</p>
              )}
            </Flex>
          )}
        </Flex>
        <CustomTextArea
          name="description"
          label="Description"
          inputwd="w-100 mb-3 mt-3"
          placeholder="Write a brief description of responsibilities."
          className="mb-3"
          value={values?.description}
          onChange={e => setValues({ ...values, description: e.target.value })}
          inputProps={{
            autoSize: {
              minRows: 2,
              maxRows: 3
            }
          }}
        />
      </Spin>
    </Modal>
  )
}
