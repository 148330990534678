import { apiSlice } from "../apiSlice"

import {
  FACEBOOK_ACCOUNT_PAGES,
  GET_NOTIFICATIONS,
  GET_USER_PROFILE,
  MARK_ALL_NOTIFICATIONS_READ,FAQ

} from "../../constants/endpoints"
import { useGetJobRequestsQuery } from "./flexTimeApiSlice"

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getNotifications: builder.query({
      query: () => ({
        url: GET_NOTIFICATIONS
      })
    }),
    markAllNotifcationsAsRead: builder.mutation({
      query: () => ({
        url: MARK_ALL_NOTIFICATIONS_READ,
        method: "POST"
      })
    }),
    getProfileData: builder.query({
      query: () => ({
        url: GET_USER_PROFILE
      })
    }),
    getConnectedFacebookPages: builder.query({
      query: () => ({
        url: FACEBOOK_ACCOUNT_PAGES
      })
    }),
    getFAQ: builder.query({
      query: () => ({
        url: FAQ
      })
    }),


  }),
  overrideExisting: false
})

export const {
  useGetNotificationsQuery,
  useMarkAllNotifcationsAsReadMutation,
  useGetProfileDataQuery,
  useGetFAQQuery,
  useGetConnectedFacebookPagesQuery,
  
} = userApiSlice
