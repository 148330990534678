import { apiSlice } from "../apiSlice"

import {
  ACCEPT_COMMUNITY_JOIN_REQUEST,
  COMMUNITY_FEED,
  COMMUNITY_MEMBERS,
  CREATE_COMMUNITY,
  DELETE_COMMUNITY,
  GET_COMMUNITY_DETAILS,
  INVITE_MEMBER_TO_COMMUNITY,
  JOIN_COMMUNITY,
  LEAVE_COMMUNITY,
  MY_CREATED_COMMUNITIES,
  MY_JOINED_COMMUNITIES,
  REJECT_COMMUNITY_JOIN_REQUEST,
  REMOVE_COMMUNITY_POST,
  SEARCH_COMMUNITIES,
  SUGGESTED_COMMUNITIES,
  UPDATE_COMMUNITY,
  VIEW_COMMUNITY_INVITATIONS,
  COMMUNITY_CATEGORIES,
  JOINED_AND_CREATED_COMMUNITIES,
  REMOVE_COMMUNITY_MEMBER,
  CANCEL_COMMUNITY_REQ
} from "../../constants/endpoints"

export const communityApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    createCommunity: builder.mutation({
      query: body => ({
        url: body.community_id
          ? `${CREATE_COMMUNITY}${body.community_id}/`
          : CREATE_COMMUNITY,
        method: body.community_id ? "PATCH" : "POST",
        body: body
      })
    }),
    myManagedCommunites: builder.query({
      query: () => ({
        url: MY_CREATED_COMMUNITIES
      }),
      providesTags: ["managed_communities"]
    }),
    getCommunityDetails: builder.query({
      query: id => ({
        url: GET_COMMUNITY_DETAILS.replace(":id", id)
      })
    }),
    updateCommunity: builder.mutation({
      query: body => ({
        url: UPDATE_COMMUNITY.replace(":id", body.community_id),
        method: "PATCH",
        body: body
      })
    }),

    deleteCommunity: builder.mutation({
      query: id => ({
        url: DELETE_COMMUNITY.replace(":id", id),
        method: "DELETE"
      })
    }),
    myJoinedCommunities: builder.query({
      query: () => ({
        url: MY_JOINED_COMMUNITIES
      }),
      providesTags: ["joined_communities"]
    }),
    suggestedCommunities: builder.query({
      query: () => ({
        url: SUGGESTED_COMMUNITIES
      }),
      providesTags: ["suggested_communities"]
    }),
    joinCommunity: builder.mutation({
      query: body => ({
        url: JOIN_COMMUNITY.replace(":id", body.id),
        method: "POST"
      }),
      invalidatesTags: ["suggested_communities"]
    }),
    viewCommunityInvitations: builder.query({
      query: id => ({
        url: VIEW_COMMUNITY_INVITATIONS.replace(":id", id)
      })
    }),
    acceptCommunityJoinReq: builder.mutation({
      query: id => ({
        url: ACCEPT_COMMUNITY_JOIN_REQUEST.replace(":invite-id", id),
        method: "POST"
      })
    }),
    rejectCommunityJoinReq: builder.mutation({
      query: body => ({
        url: REJECT_COMMUNITY_JOIN_REQUEST.replace(":id", body.id),
        body: body,
        method: "POST"
      })
    }),
    getCommunitySocialFeed: builder.query({
      query: ({ id, page = 1 }) => ({
        url: COMMUNITY_FEED.replace(":id", id) + `?page=${page}`
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        return { id: queryArgs.id }
      },
      transformResponse: (response, meta, arg) => {
        const cachedData = meta?.currentCache || {
          results: [],
          next: null,
          count: 0
        }

        return {
          results: [...cachedData.results, ...response.results],
          next: response.next,
          count: response.count
        }
      },
      providesTags: ["community_social_feed"]
    }),
    removeCommunityPost: builder.mutation({
      query: body => ({
        url: REMOVE_COMMUNITY_POST.replace(":id", body.communityId),
        method: "PATCH",
        body: { post_id: body.postId }
      })
    }),
    searchCommunities: builder.query({
      query: ({ searchText = "" }) => {
        if (searchText.length === 0) return { url: null }
        return {
          url: SEARCH_COMMUNITIES + `?search=${searchText}`
        }
      }
    }),
    leaveCommunity: builder.mutation({
      query: id => ({
        url: LEAVE_COMMUNITY.replace(":id", id),
        method: "POST"
      })
    }),
    getCommunityMembers: builder.query({
      query: ({ id, searchTerm }) => ({
        url: COMMUNITY_MEMBERS.replace(":id", id).concat(
          `?search=${searchTerm}`
        )
      }),
      providesTags: ["community_members"]
    }),
    removeCommunityMember: builder.mutation({
      query: ({ communityId, userId }) => ({
        url: REMOVE_COMMUNITY_MEMBER.replace(":id", communityId),
        body: {
          user_id: userId
        },
        method: "POST"
      })
    }),
    inviteMemberToCommunity: builder.mutation({
      query: ({ communityId, recepientId }) => ({
        url: INVITE_MEMBER_TO_COMMUNITY.replace(":id", communityId),
        body: { recipient_id: recepientId },
        method: "POST"
      })
    }),
    getCommunityCategories: builder.query({
      query: () => ({
        url: COMMUNITY_CATEGORIES
      })
    }),
    getAllUserCommunities: builder.query({
      query: ({ searchText = "", page = 1 }) => ({
        url:
          JOINED_AND_CREATED_COMMUNITIES +
          `?page=${page}` +
          `${searchText ? "&name=" + searchText : ""}`
      })
    }),
    cancelCommunityReq: builder.mutation({
      query: body => ({
        url: CANCEL_COMMUNITY_REQ,
        method: "POST",
        body: body
      })
    })
  }),

  overrideExisting: false
})

export const {
  useCreateCommunityMutation,
  useMyManagedCommunitesQuery,
  useGetCommunityDetailsQuery,
  useUpdateCommunityMutation,
  useDeleteCommunityMutation,
  useMyJoinedCommunitiesQuery,
  useSuggestedCommunitiesQuery,
  useJoinCommunityMutation,
  useViewCommunityInvitationsQuery,
  useAcceptCommunityJoinReqMutation,
  useRejectCommunityJoinReqMutation,
  useGetCommunitySocialFeedQuery,
  useRemoveCommunityPostMutation,
  useSearchCommunitiesQuery,
  useLeaveCommunityMutation,
  useGetCommunityMembersQuery,
  useRemoveCommunityMemberMutation,
  useInviteMemberToCommunityMutation,
  useGetCommunityCategoriesQuery,
  useGetAllUserCommunitiesQuery,
  useCancelCommunityReqMutation
} = communityApiSlice
