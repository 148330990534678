import React from "react"
import { useNavigate, useParams } from "react-router-dom"
import {
  Avatar,
  Card,
  Col,
  Divider,
  Empty,
  Flex,
  Image,
  Row,
  Space,
  Typography,
  Spin
} from "antd"

import arrowLeft from "../../assets/common/arrowLeft.png"

import {
  ClockIcon,
  CoinsDollarIcon,
  LocationIcon,
  UserMultipleIcon
} from "../../assets/rawSvgs"
import { useGetJobRequestDetailQuery } from "../../api/apiSlices/flexTimeApiSlice"
import { Routes } from "../../constants/routes"
import { getUser } from "../../api/auth"
import './styles.scss'

const { Text, Paragraph, Title } = Typography

const JobDetails = () => {
  const { id } = useParams()
  let navigate = useNavigate()
  const currentUser = getUser()

  const {
    data: jobRequestsDetail,
    isLoading: isJobRequestDetailLoading,
    refetch: refetchJobRequestDetail
  } = useGetJobRequestDetailQuery(id)

  const isEmployer = currentUser?.role === "employer"

  return (
    <>
     <Spin spinning={isJobRequestDetailLoading}>
      <Space style={{ marginBottom: "15px", gap: "12px" }}>
        <Image
          src={arrowLeft}
          preview={false}
          onClick={() => {
            navigate(isEmployer ? Routes.EMPLOYER_JOBS : Routes.WORKER_JOBS);
          }}
          style={{ cursor: 'pointer' }}
        />
        <Title level={4}>Gig Details</Title>
      </Space>
      <Row gutter={[24]}>
        <Col xl={6} xxl={5}>
          <Card>
            <Flex vertical>
              <Flex gap={12}>
                <Avatar
                  size={36}
                  icon={<UserMultipleIcon />}
                  style={{ backgroundColor: "#F0F5FF" }}
                />
                <Flex vertical>
                  <Text className="TertiaryColor font-weight-500">
                    {isEmployer ? "Required Workers" : "Employer"}
                  </Text>
                  <Text className="font-weight-500">
                    {isEmployer
                      ? jobRequestsDetail?.number_of_workers ?? "-"
                      : jobRequestsDetail?.employer?.name ?? "Anonymous"}
                  </Text>
                </Flex>
              </Flex>

              <Divider />

              <Flex gap={24} vertical>
                <Flex gap={12}>
                  <Avatar
                    size={36}
                    icon={<CoinsDollarIcon />}
                    style={{ backgroundColor: "#F0F5FF" }}
                  />
                  <Flex vertical>
                    <Text className="TertiaryColor font-weight-500">
                      Hourly Rate
                    </Text>
                    <Text className="font-weight-500">
                      ${jobRequestsDetail?.budget_min} - $
                      {jobRequestsDetail?.budget_max}
                    </Text>
                  </Flex>
                </Flex>

                <Flex gap={12}>
                  <Avatar
                    size={36}
                    icon={<ClockIcon />}
                    style={{ backgroundColor: "#F0F5FF" }}
                  />
                  <Flex vertical>
                    <Text className="TertiaryColor font-weight-500">
                      Duration
                    </Text>
                    <Text className="font-weight-500">
                      {jobRequestsDetail?.start_date} to{" "}
                      {jobRequestsDetail?.end_date}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>

              <Divider />

              <Flex gap={12}>
                <Avatar
                  size={36}
                  icon={<LocationIcon />}
                  style={{ backgroundColor: "#F0F5FF" }}
                />
                <Flex vertical>
                  <Text className="TertiaryColor font-weight-500">
                    Location
                  </Text>
                  <Text className="font-weight-500">
                    {jobRequestsDetail?.location ?? "Not Given"}
                  </Text>
                </Flex>
              </Flex>

              <Divider />

              <Flex vertical gap="small">
                <Text type="secondary" className="text-xs-500">
                  Skills
                </Text>
                <Flex gap={12} wrap="wrap" className="my-scrollable-div" style={{
    maxHeight: "200px",        
    overflowY: "auto",         
    paddingRight: "8px",
      
  }}>
                  {jobRequestsDetail?.skills?.map((skill, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          padding: "4px 8px",
                          borderRadius: "24px",
                          border: "1px solid #ADC6FF",
                          backgroundColor: "#F0F5FF",
                          color: "#10239E",
                          fontSize: "12px",
                          fontWeight: 500,
                          lineHeight: "18px",
                          fontStyle: "normal",
                          whiteSpace: "nowrap",   
                        }}
                      >
                        {skill}
                      </div>
                    )
                  })}
                </Flex>
              </Flex>
            </Flex>
          </Card>
        </Col>
        <Col xl={18} xxl={19}>
          <Flex vertical gap="large">
            <Card>
              <Title level={4}>Survey</Title>
              {jobRequestsDetail?.description ? (
                <Paragraph className="mb-0" style={{ whiteSpace: 'pre-wrap' }}>
                  {jobRequestsDetail?.description}
                </Paragraph>
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </Card>
          </Flex>
        </Col>
      </Row>
      </Spin>
    </>
  )
}

export default JobDetails
