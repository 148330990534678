import { Modal , Radio ,Typography,Flex,Divider, Button , Input ,Select ,Form} from "antd";
import CustomInput from "../../components/customInput/CustomInput";
import CustomSelect from "../../components/customSelect/CustomSelect";
import Header from "../user-experience/components/Header";
import { useFormik } from "formik";
import { useUpdateUserInfoMutation } from "../../api/apiSlices/InformationModalApiSlice";
import { useUpdateInfoMutation } from "../../api/apiSlices/socialMediaApiSlice";
import { openNotification } from "../../util/openNotifications";
import * as Yup from 'yup';
import React,{useState} from 'react'
import './steps.scss'

const {Text , Title}=Typography
const { Option } = Select;

const validationSchema = Yup.object().shape({
  age: Yup.number()
    .required('Age is required')
    .typeError('Age must be a valid number'),
    marital_status: Yup.string()
    .required('Marital status is required'),
    no_of_children: Yup.number()
  .typeError('Please enter a valid number') 
  .min(0, 'Number of children cannot be negative') 
  .integer('Number of children must be an integer') 
  .required('Please enter the number of children'), 

});
//error handling kro 
// or catch ka bhi console.log hatao 
function Step1() {
    const title='Help Us Tailor Your Experience!'
    const [modalVisible, setModalVisible] = useState(true);
    const [noOfChildren , setnoOfChildren]=useState(false);
    const description="We're excited to learn more about you so we can help match you with the best opportunities and resources."
    const [updateUserInfo, { isLoading, error }] = useUpdateUserInfoMutation();

 
 const handleCancel = () => {
  setModalVisible(false);  
 
};
    const formik = useFormik({
      initialValues: {
        age: 0,
        marital_status: '',
        no_of_children: 0,
      },
      validationSchema:validationSchema,
      onSubmit: (values) => {
        console.log("Form Values Submitted:", values);
        updateUserInfo(values)  
      .unwrap() 
      .then((response) => {
        console.log("Response from API:", response);
      })
      .catch((error) => {
        console.error("Error during API call:", error);
      });
  },
    });
    
    return (
      <Form onSubmit={formik.handleSubmit}>
    <div>
        <Modal  
         title={
            <span className="custom-text-style " style={{ color: '#00000073'}}>
              Let’s Get To Know You More
            </span>}
        open={modalVisible} 
        onCancel={handleCancel}
        footer={
         <div>
          <Flex  gap="8px" justify="flex-end">
            <Button type="default" onClick={()=>handleCancel()}>Maybe Later</Button>
            <Button  onClick={() => {
               formik.handleSubmit();
   
   
  }} type="primary">Submit</Button>
          </Flex>
        </div>
      }
      
      >
        <Divider style={{ marginTop: '8px', marginBottom: '16px' }} /> 
        <Flex vertical gap='16px'>


      <div >
       
         <Title level={4} className="text-style">Help Us Tailor Your Experience!</Title>
         {/* <Header  description={description}></Header> */}
         <Text className="description">We're excited to learn more about you so we can help match you with the best opportunities and resources.</Text>
        
      </div>



      <div>

        <Text className="text-style-lines"  style={{marginTop:'0px'}}>What’s your age?</Text>
      <Input
  

  name="age"
  placeholder="Enter"
  label="What’s your age?"
  value={formik.values.age === 0 ? '' : formik.values.age} 
  onChange={formik.handleChange}
  onBlur={formik.handleBlur}
/>

{formik.touched.age && formik.errors.age && (
  <div style={{ color: 'red', marginTop: '4px' }}>{formik.errors.age}</div>
)}
      </div>




      <div>
      <Text className="text-style-lines">What’s your marital status?</Text>
      <Select
         name="marital_status"
         value={formik.values.marital_status || ""}
         onChange={(value) => {
          console.log("Selected value:", value); 
          formik.setFieldValue("marital_status", value);
        }}
          placeholder="Select"
          style={{ width: '100%' }}
>
           <Option value="single">Single</Option>
           <Option value="married">Married</Option>
            <Option value="divorced">Divorced</Option>
          </Select>

             {formik.touched.marital_status && formik.errors.marital_status && (
               <div style={{ color: 'red', marginTop: '4px' }}>{formik.errors.marital_status}</div>
               )}
      </div>




      <div>
        <Flex vertical>
          <Text className="text-style-lines">Do You Have Children?</Text>
          <Flex>
          <Radio.Group 
          onChange={(e) =>{
            const IsNoOfChildern=e.target.value ===true; 
            setnoOfChildren((IsNoOfChildern))
          }}
          value={noOfChildren}
>
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
        {formik.touched.no_of_children && formik.errors.no_of_children && (
              <div style={{ color: 'red', marginTop: '4px' }}>{formik.errors.no_of_children}</div>
            )}
          </Flex>
          </Flex>
      </div>








     { noOfChildren && ( <div>
  <Flex vertical>
    <Text className="text-style-lines">How Many Children Do You Have?</Text>
    <Flex>
      <Input
        type="number"
        min="0"
        onChange={(e) => formik.setFieldValue('no_of_children', e.target.value)}
        value={formik.values.age === 0 ? '' : formik.values.age} 
        placeholder="Enter"
      />
      {formik.touched.no_of_children && formik.errors.no_of_children && (
        <div style={{ color: 'red', marginTop: '4px' }}>{formik.errors.no_of_children}</div>
      )}
    </Flex>
  </Flex>
</div>)}

      </Flex>
      </Modal>
    </div></Form>
  )
}

export default Step1
