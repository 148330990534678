import { Button, Flex, Modal, Spin } from "antd"
import Header from "./Header"
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons"
import { useState } from "react"
import ExperienceInputModal from "./ExperienceInputModal"
import {
  useDeleteWorkExpereienceMutation,
  useGetWorkerProfileInfoQuery
} from "../../../api/apiSlices/workerApiSlice"
import WorkExperienceCard from "./WorkExperienceCard"
import { openNotification } from "../../../util/openNotifications"
import "./styles.scss"

const DESCRIPTION =
  "Briefly share your past job roles and key responsibilities. This helps us understand your background and how you can contribute to our team."

const ProfessionalExperience = ({
  wrapperClassName = "",
  onNextClick = null,
  onPreviousClick = null,
  skipStep,
  isEditMode = false
}) => {
  const { data, isLoading, refetch } = useGetWorkerProfileInfoQuery()
  const [deleteWorkExperience] = useDeleteWorkExpereienceMutation()
  const [modal, contextHolder] = Modal.useModal()

  const [isOpenExperienceInputModal, setIsOpenExperienceInputModal] =
    useState(false)
  const [workExpToEdit, setWorkExpToEdit] = useState()

  const onEditExp = workExp => {
    setWorkExpToEdit(workExp)
    setIsOpenExperienceInputModal(true)
  }

  const onCloseExpInputModal = (success = false) => {
    if (success) {
      refetch()
    }
    if (workExpToEdit) setWorkExpToEdit(null)
    setIsOpenExperienceInputModal(false)
  }

  const onDeleteWorkExp = workExp => {
    modal.confirm({
      title: "Delete Work Expereince",
      icon: <ExclamationCircleOutlined />,
      content:
        "Are you sure you want to delete this experience? This action cannot be undone.",
      okText: "Delete",
      cancelText: "Cancel",
      okButtonProps: { danger: true },
      onOk: () =>
        deleteWorkExperience(workExp.id).then(() => {
          refetch()
          openNotification({
            type: "success",
            message: "Experience deleted successfully!"
          })
        }),
      centered: true
    })
  }

  return (
    <>
      <div className={wrapperClassName}>
        <Spin spinning={isLoading}>
          <Header title="Add work experience" description={DESCRIPTION} />
          <div className="workExpContainer">
            <div
              className="addWorkExp"
              onClick={() => setIsOpenExperienceInputModal(true)}
            >
              <PlusOutlined />
              <h5>Add Work Experience </h5>
            </div>
            <div className="workExpList">
              {data?.work_experience.map(workExp => (
                <WorkExperienceCard
                  workExp={workExp}
                  onEditExp={onEditExp}
                  onDeleteWorkExp={onDeleteWorkExp}
                />
              ))}
            </div>
          </div>

          {isOpenExperienceInputModal && (
            <ExperienceInputModal
              onClose={onCloseExpInputModal}
              initialData={workExpToEdit ? workExpToEdit : null}
            />
          )}
          {contextHolder}
        </Spin>
      </div>
      {onNextClick && (
        <Flex justify="space-between">
          {!isEditMode && (
            <Button onClick={skipStep} type="text">
              Skip this step
            </Button>
          )}
          <Flex gap="12px">
            <Button type="default" onClick={onPreviousClick}>
              Back
            </Button>

            <Button type="primary" onClick={onNextClick}>
              Next
            </Button>
          </Flex>
        </Flex>
      )}
    </>
  )
}

export default ProfessionalExperience
