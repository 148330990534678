import React, { useState } from "react"
import {
  Avatar,
  Button,
  Col,
  Divider,
  Menu,
  Row,
  Typography,
  Steps,
  Card,
  Flex,
  Space,
  Badge,
  Empty,
  Progress,
  Rate,
  Upload,
  Spin
} from "antd"
import { UserOutlined } from "@ant-design/icons"

import CustomModal from "../../components/Modal/CustomModal"
import CustomInput from "../../components/customInput/CustomInput"
import CustomSelect from "../../components/customSelect/CustomSelect"
import WorkExperienceJobDescription from "./components/WorkExperience"

import {
  useEditWorkerProfileMutation,
  useGetCountriesQuery,
  useGetWorkerProfileQuery
} from "../../api/apiSlices/flexTimeApiSlice"

import {
  BriefcaseIcon,
  EditIcon,
  FilledStarIcon,
  LinkedInIcon,
  RedLocationIcon,
  Yellow28EditIcon,
  YellowEmaislIcon
} from "../../assets/rawSvgs"

import TextArea from "antd/es/input/TextArea"

import styles from "../profile/profile.module.scss"
import { openNotification } from "../../util/openNotifications"
import { useAddMediaMutation } from "../../api/apiSlices/settingsApiSlice"
import ProfessionalExperience from "../user-experience/components/ProfessionalExperience"
import CreateProfile from "../user-experience/components/CreateProfile"
import Education from "../user-experience/components/Education"
import Skills from "../user-experience/components/Skills"
import UserExperience from "../user-experience/UserExperience"
import { useLocation } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import Dayjs from "dayjs"
import { Routes } from "../../constants/routes"
import { ROLES } from "../../constants/roles"
import { getUser } from "../../api/auth"
import { getQueryParams } from "../../util/getQueryParams"
const { Title, Text, Paragraph } = Typography

const WorkerProfile = () => {
  const [open, setOpen] = useState(false)
  const [show, setShow] = useState(true)
  const [country, setCountry] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [profilePicture, setProfilePicture] = useState({})
  const location = useLocation()

  const { token } = getQueryParams(location?.search)

  const isEmployer = getUser()?.role === ROLES.EMPLOYER

  const isEmployerAuthenticated = isEmployer && !!token

  const {
    data: workerProfile,
    isLoading: isWorkerProfileLoading,
    refetch: refetchWorkerProfile
  } = useGetWorkerProfileQuery(token)

  const {
    data: countries,
    isLoading: isCountriesLoading,
    refetch: refetchCountries
  } = useGetCountriesQuery(undefined, {
    skip: isEmployer
  })

  const [
    editWorkerProfile,
    { isLoading: isEditWorkerProfileLoading, error: editWorkerProfileError }
  ] = useEditWorkerProfileMutation()

  const [addMedia, { isLoading: isAddMediaLoading, error: addMediaError }] =
    useAddMediaMutation()

  const mapExperienceData = experienceData => {
    return experienceData?.map(item => {
      const startDate = new Date(item.start_date)
      const endDate = item.currently_working
        ? new Date()
        : new Date(item.end_date)
      const duration = calculateDuration(startDate, endDate)

      return {
        title: `${capitalize(item.job_type)} . ${formatDateRange(startDate, item.currently_working ? null : endDate)} . ${duration}`,
        description: (
          <WorkExperienceJobDescription
            company={item?.company_name}
            jobTitle={item?.job_title}
            jobDescription={item?.description}
          />
        )
      }
    })
  }

  const mapEducationData = educationData => {
    return educationData?.map(item => {
      const startMonth = item.start_month ? Dayjs(item.start_month).format("MMM") : "N/A";
      const startYear = item.start_year || "";
      const endMonth = item.end_month ? Dayjs(item.end_month).format("MMM") : "N/A";
      const endYear = item.end_year || "";
      return {
        title: `${capitalize(item.school)} . ${startMonth} ${startYear} - ${endMonth} ${endYear}`,

        description: (
          <WorkExperienceJobDescription
            company={item?.degree}
            jobTitle={item?.field_of_study}
            jobDescription={item?.description}
          />
        )
      }
    })
  }

  // Helper functions
  const calculateDuration = (startDate, endDate) => {
    const years = endDate.getFullYear() - startDate.getFullYear()
    const months = endDate.getMonth() - startDate.getMonth() + years * 12
    return `${Math.floor(months / 12)} year(s) ${months % 12} month(s)`
  }

  const formatDateRange = (startDate, endDate) => {
    const startMonthYear = startDate.toLocaleString("default", {
      month: "short",
      year: "numeric"
    })
    const endMonthYear = endDate
      ? endDate.toLocaleString("default", { month: "short", year: "numeric" })
      : "Present"
    return `${startMonthYear} - ${endMonthYear}`
  }

  const capitalize = str =>
    str.charAt(0).toUpperCase() + str.slice(1).replace("_", " ")

  const showModal = () => {
    setOpen(true)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const handleUpload = async ({ file }) => {
    const formData = new FormData()
    formData.append("file", file)
    try {
      const data = await addMedia(formData).unwrap()
      setProfilePicture(data)
    } catch (error) {
      openNotification({ type: "error", message: error?.message })
    }
  }

  const handleOk = async () => {
    try {
      const payload = {
        first_name: firstName,
        last_name: lastName,
        country: country,
        object_key: profilePicture?.object_key
      }
      await editWorkerProfile(payload).unwrap()
      refetchWorkerProfile()
      setOpen(false)
      openNotification({
        type: "success",
        message: "Worker profile edited successfully"
      })
    } catch (error) {
      console.log("error", error)
    }
  }

  const [currentStep, setCurrentStep] = useState(null)
  const navigate = useNavigate()
  const handleEditClick = step => {
    // console.log("Edit button clicked, setting step to:", step);
    // setCurrentStep(step)
    navigate(`${Routes.USER_EXPERIENCE_EDIT.replace(":step", step)}?mode=edit`)
  }
  console.log("workerprofile", workerProfile);
  if (isEmployer && !token) {
    return <Empty description="You are not authorised to view this page" />
  }
  return (
    <Spin spinning={isWorkerProfileLoading}>
      <div className="profileWrapper">
        <Row gutter={[24]}>
          <Col xl={6} xxl={5}>
            <Card className="leftPanel">
              <Flex vertical>
                <Flex vertical gap="small" className="mb-4">
                  <Badge status="success" dot={show} offset={[-13, 87]}>
                  
                    <Avatar
                      size={100}
                      src={
                        workerProfile?.profile?.profile_picture
                          ? workerProfile.profile.profile_picture
                          : null
                      }
                      icon={
                        !workerProfile?.profile?.profile_picture && (
                          <UserOutlined />
                        )
                      }
                    />
                    
                  </Badge>
                  <div className="">
                    <Title level={4} className="mb-1" aria-label="name">
                      {workerProfile?.profile?.user?.name ?? "Anonymous"}
                    </Title>
                    <Text className="text-xs-500" style={{ color: "#7A72EF" }}>
                      {workerProfile?.profile?.professional_role ?? "Not Given"}
                    </Text>
                  </div>
                  {!isEmployerAuthenticated && (
                    <CustomModal
                      open={open}
                      showModal={showModal}
                      title="Profile Information"
                      primeBtnText="Save"
                      handleCancel={handleCancel}
                      handleOk={handleOk}
                      isDisabled={isAddMediaLoading}
                      isLoading={isEditWorkerProfileLoading}
                      ModalTrigger={
                        <Button type="primary" size="middle" block>
                          Edit Profile
                        </Button>
                      }
                      ModalBody={
                        <>
                          <Space direction="vertical" size="middle">
                            <div className={styles.EditUserImg}>
                              <Avatar
                                size={74}
                                src={
                                  workerProfile?.profile?.profile_picture
                                    ? workerProfile.profile.profile_picture
                                    : profilePicture?.url
                                }
                                icon={
                                  !workerProfile?.profile?.profile_picture &&
                                  !profilePicture?.url && <UserOutlined />
                                }
                                style={{ backgroundColor: "#3DC2DC" }}
                              />
                              <span className={styles.editIcon}>
                                <Upload
                                  customRequest={handleUpload}
                                  showUploadList={false}
                                  disabled={isAddMediaLoading}
                                >
                                  <Yellow28EditIcon />
                                </Upload>
                              </span>
                            </div>
                            <Flex gap={12}>
                              <CustomInput
                                inputwd="w-50"
                                placeholder="Enter Name"
                                label="First Name"
                                className="mb-3"
                                value={firstName}
                                onChange={e => setFirstName(e.target.value)}
                                type="text"
                                // errorMessage={errors?.name}
                              />
                              <CustomInput
                                inputwd="w-50"
                                placeholder="Enter Name"
                                label="Last Name"
                                className="mb-3"
                                value={lastName}
                                onChange={e => setLastName(e.target.value)}
                                type="text"
                                // errorMessage={errors?.username}
                              />
                            </Flex>
                            <CustomSelect
                              label="Country"
                              placeholder="Select Country"
                              value={country}
                              handleChange={value => setCountry(value)}
                              options={countries?.map(country => ({
                                label: country?.name,
                                value: country?.name
                              }))}
                            />
                          </Space>
                        </>
                      }
                    />
                  )}
                  {/* <CustomModal
                  open={open}
                  showModal={showModal}
                  title="Give Ratings"
                  primeBtnText="Save"
                  // handleCancel={handleCancel}
                  ModalTrigger={
                    <Button type="primary" size="middle" block>
                      Give Review
                    </Button>
                  }
                  ModalBody={
                    <>
                      <Space direction="vertical" size={12}>
                        <Text>
                          This job has been successfully completed. Please rate
                          how the worker performed.
                        </Text>
                        <Rate allowHalf defaultValue={2.5} />
                        <TextArea
                          // value={description}
                          // onChange={e => setDescription(e.target.value)}
                          rows={3}
                          placeholder="Write review"
                        />
                      </Space>
                    </>
                  }
                /> */}
                </Flex>
                <div className={styles.UserStats}>
                  <Flex vertical gap="middle" className="p-3">
                    {/* <Flex gap={12}>
                        <div className="">💰</div>
                        <Flex vertical gap={4}>
                          <Text type="secondary">Total Earnings</Text>
                          <Text style={{ fontSize: "16px" }}>$7k+</Text>
                        </Flex>
                      </Flex> */}
                    <hr
                      style={{
                        borderColor: "#FFD8BF"
                      }}
                    />
                    <Flex gap={12}>
                      <div className="">💻</div>
                      <Flex vertical gap={4}>
                        <Text type="secondary">Total Jobs</Text>
                        <Text style={{ fontSize: "16px" }}>5</Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </div>
                <Divider />
                <Flex gap={12}>
                  <Avatar
                    size={36}
                    icon={<RedLocationIcon />}
                    style={{ backgroundColor: "#FFF0F6" }}
                  />
                  <Flex vertical>
                    <Text className="TertiaryColor font-weight-500">
                      Location
                    </Text>
                    <Text className="font-weight-500">
                      {" "}
                      {workerProfile?.profile?.country }
                    </Text>
                  </Flex>
                </Flex>
                <Divider />
                <Flex gap={12}>
                  <Avatar
                    size={36}
                    icon={<YellowEmaislIcon />}
                    style={{ backgroundColor: "#FFFBE6" }}
                  />
                  <Flex vertical>
                    <Text className="TertiaryColor font-weight-500">Email</Text>
                    <Text className="font-weight-500">
                      {workerProfile?.profile?.user?.email ?? "Not Given"}
                    </Text>
                  </Flex>
                </Flex>
                {/* removing as we have removed LinkedIn integration */}
                {/* <Divider />

                  <Flex vertical gap="small">
                    <Flex align="center" justify="space-between">
                      <Text type="secondary" className="text-xs-500">
                        Socials
                      </Text>
                      {!isEmployerAuthenticated && <EditIcon />}
                    </Flex>
                    {/* <Flex gap={12}>
                      <LinkedInIcon />
                      <Flex vertical>
                        <Text className="TertiaryColor font-weight-500">
                          Linkedin
                        </Text>
                        <Text className="font-weight-500">
                          angelo-libero-a42a0438
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex> */}
                <Divider />
                <Flex vertical gap="small">
                  <Flex align="center" justify="space-between">
                    <Flex>
                      <Text type="secondary" className="text-xs-500">
                        Skills
                      </Text>
                    </Flex>
                    {!isEmployerAuthenticated && (
                      <Flex
                        onClick={() => handleEditClick(3)}
                        className="cursor-pointer"
                      >
                        <EditIcon />
                      </Flex>
                    )}
                  </Flex>
                  <Flex gap={12} wrap="wrap">
                    {workerProfile?.skills?.length === 0 ? (
                      <Space style={{ margin: "auto" }}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </Space>
                    ) : (
                      workerProfile?.skills?.map(skill => {
                        return (
                          <div className={styles.SkillsPill}>{skill?.name}</div>
                        )
                      })
                    )}
                  </Flex>
                </Flex>
              </Flex>
            </Card>
          </Col>
          <Col xl={18} xxl={19}>
            <Flex
              vertical
              gap="large"
              // className={styles.profileViewScroll}
            >
              {!workerProfile?.profile?.is_profile_completed &&
                !isEmployerAuthenticated && (
                  <Card className="completeProfileCard">
                    <Title level={4} className="mb-3">
                      Complete Profile to apply to opportunities
                    </Title>
                    <Paragraph
                      style={{ fontSize: "16px", marginBottom: "24px" }}
                    >
                      A complete profile helps you stand out and makes it easier
                      for employers to find you. Finish filling out your profile
                      to start applying for opportunities.
                    </Paragraph>
                    <Button onClick={() => navigate(Routes.USER_EXPERIENCE)}>
                      Complete Profile
                    </Button>
                  </Card>
                )}

              <Card>
                <Flex align="center" justify="space-between" className="mb-12">
                  <Title level={4}>
                    {workerProfile?.profile?.professional_role ??
                      "Your Professional Role"}
                  </Title>
                  {!isEmployerAuthenticated && (
                    <Button onClick={() => handleEditClick(0)}>
                      <EditIcon />
                    </Button>
                  )}
                </Flex>
                {workerProfile?.profile?.description ? (
                  <Paragraph className="mb-0">
                    {workerProfile?.profile?.description}
                  </Paragraph>
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
                {currentStep !== null && (
                  <UserExperience current={currentStep} />
                )}
              </Card>
              <Card>
                <Flex align="center" justify="space-between" className="mb-12">
                  <Title level={4}>Work Experience</Title>
                  {!isEmployerAuthenticated && (
                    <Button onClick={() => handleEditClick(1)}>
                      <EditIcon />
                    </Button>
                  )}
                </Flex>
                {workerProfile?.work_experience?.length > 0 ? (
                  <Steps
                    progressDot
                    current={1}
                    direction="vertical"
                    items={
                      workerProfile &&
                      mapExperienceData(workerProfile?.work_experience)
                    }
                  />
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </Card>
              <Card>
                <Flex align="center" justify="space-between" className="mb-12">
                  <Title level={4}>Education</Title>
                  {!isEmployerAuthenticated && (
                    <Button onClick={() => handleEditClick(2)}>
                      <EditIcon />
                    </Button>
                  )}
                </Flex>
                {workerProfile?.profile?.education?.length > 0 ? (
                  <Steps
                    progressDot
                    current={1}
                    direction="vertical"
                    items={
                      workerProfile &&
                      mapEducationData(workerProfile?.profile?.education)
                    }
                  />
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </Card>
              <Card style={{ marginBottom: "24px" }}>
                <Flex vertical gap="middle">
                  <Title level={4} className="mb-1" aria-label="name">
                    Ratings and Reviews
                  </Title>
                  {workerProfile?.reviews?.length > 0 ? (
                    <>
                      <Flex gap={12} align="center">
                        <Flex gap="small">
                          <FilledStarIcon />
                          <Text className="text-lg-500">4.5</Text>
                        </Flex>
                        <div className="dot dot_small"></div>
                        <Text className="text-lg-500" type="secondary">
                          1,435 reviews{" "}
                        </Text>
                      </Flex>
                      <Flex vertical gap="small">
                        <Flex gap="small">
                          <Text className="text-lg-500">5 stars</Text>
                          <Progress
                            percent={90}
                            showInfo={false}
                            style={{ width: "269px" }}
                            size={[269, 9]}
                            strokeColor="#000"
                          />
                          <Text className="text-lg-500">(1,234)</Text>
                        </Flex>
                        <Flex gap="small">
                          <Text className="text-lg-500">4 stars</Text>
                          <Progress
                            percent={56}
                            showInfo={false}
                            style={{ width: "269px" }}
                            size={[269, 9]}
                            strokeColor="#000"
                          />
                          <Text className="text-lg-500">(76)</Text>
                        </Flex>
                        <Flex gap="small">
                          <Text className="text-lg-500">3 stars</Text>
                          <Progress
                            percent={12}
                            showInfo={false}
                            style={{ width: "269px" }}
                            size={[269, 9]}
                            strokeColor="#000"
                          />
                          <Text className="text-lg-500">(12)</Text>
                        </Flex>
                        <Flex gap="small">
                          <Text className="text-lg-500">2 stars</Text>
                          <Progress
                            percent={0}
                            showInfo={false}
                            style={{ width: "269px" }}
                            size={[269, 9]}
                            strokeColor="#000"
                          />
                          <Text className="text-lg-500">(0)</Text>
                        </Flex>
                        <Flex gap="small">
                          <Text className="text-lg-500">1 stars</Text>
                          <Progress
                            percent={0}
                            showInfo={false}
                            style={{ width: "269px" }}
                            size={[269, 9]}
                            strokeColor="#000"
                          />
                          <Text className="text-lg-500">(0)</Text>
                        </Flex>
                      </Flex>
                    </>
                  ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )}
                </Flex>
              </Card>
            </Flex>
          </Col>
        </Row>
      </div>
    </Spin>
  )
}

export default WorkerProfile
