import React from 'react';
import Header from './Header';
import { Form, Button, Input, DatePicker, Select, Row, Col } from 'antd';
import { Typography } from 'antd';
import CustomSelect from '../../../components/customSelect/CustomSelect';
import DayJs from "dayjs"

import { useGetCountriesQuery } from '../../../api/apiSlices/flexTimeApiSlice';
import { jobDetails } from '../../../schemas/JobCreationSchema';

const { RangePicker } = DatePicker;
const { Title } = Typography;
const { Text } = Typography;

function JobDetails({
  onNextClick,
  onPreviousClick,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  setValues
}) {
  const DESCRIPTION = "Provide below mentioned details to help candidates understand the job.";
  const DESCRIP = "Enter your hourly rate (USD) between $20 and $150 to align with platform standards";
  const {
    data: countries,
    isLoading: isCountriesLoading,
    refetch: refetchCountries
  } = useGetCountriesQuery()

  return (
    <>
      <div>
        <Header title="Job Details" description={DESCRIPTION} />

        <Form layout="vertical">
          <Title level={5}>Hourly Rate</Title>
          
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="From"
                validateStatus={touched?.rateMin && errors?.rateMin ? 'error' : ''}
                help={touched?.rateMin && errors?.rateMin}
              >
                <Input
                  type="number"
                  name="rateMin"
                  value={values?.rateMin || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  suffix="/hr"
                  step="0.1"
                />
                {/* {touched["rateMin"] && errors?.rateMin
                  ? "error"
                  : undefined} */}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="To"
                validateStatus={touched?.rateMax && errors?.rateMax ? 'error' : ''}
                help={touched?.rateMax && errors?.rateMax}
              >
                <Input
                  type="number"
                  name="rateMax"
                  value={values?.rateMax || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  suffix="/hr"
                  step="0.1"
                />
              </Form.Item>
            </Col>
          </Row>
          
        
           <Text>{DESCRIP}</Text> 
        

         <div style={{ marginTop: '16px'}}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Number of Workers Needed"
                validateStatus={touched?.workersNeeded && errors?.workersNeeded ? 'error' : ''}
                help={touched?.workersNeeded && errors?.workersNeeded}
              >
                <Input
                  type="number"
                  name="workersNeeded"
                  value={values?.workersNeeded||''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {/* {errors?.jobDetails?.workersNeeded&&<div>{errors?.jobDetails.workersNeeded}</div>} */}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Job Duration"
                validateStatus={touched?.dateRange && errors?.dateRange ? 'error' : ''}
                help={touched?.dateRange && errors?.dateRange}
              >
            


<RangePicker
  name="dateRange"
 
  // value={
  //   values?.dateRange?.length === 2
  //     ? [DayJs(values.dateRange[0]:undefined), DayJs(values.dateRange[1])]
  //     : undefined
  // }

  value={
    values?.dateRange && values.dateRange[0] && values.dateRange[1]
      ? [DayJs(values.dateRange[0]).isValid() ? DayJs(values.dateRange[0]) : undefined,
         DayJs(values.dateRange[1]).isValid() ? DayJs(values.dateRange[1]) : undefined]
      : undefined
  }
  
  disabledDate={(current) => current && current < DayJs().endOf('day')}


  

  onChange={(_, dates) => {
    if (dates && dates.length === 2) {
      setValues({ ...values, dateRange: dates });
    } else {
      // If cleared, reset to null or empty array
      setValues({ ...values, dateRange:undefined });
    }
  }}
  
  
/>

               
              </Form.Item>
            </Col>
          </Row>
          </div>
          <Form.Item
            label="Location"
            validateStatus={touched?.location && errors?.location ? 'error' : ''}
            help={touched?.location && errors?.location}
          >

                    <CustomSelect
                          
                            placeholder="Select Country"
                            value={values?.location}
                            handleChange={value => setFieldValue('location',value)}
                            options={countries?.map(country => ({
                              label: country?.name,
                              value: country?.name
                            }))}
                          />
          </Form.Item>

          <Form.Item
            label="Additional Notes"
            // validateStatus={touched?.notes && errors?.notes ? 'error' : ''}
            // help={touched?.notes && errors?.notes}
          >
            <Input.TextArea
              name="notes"
              value={values?.notes||''}
              onChange={handleChange}
              onBlur={handleBlur}
              rows={4}
            />
          </Form.Item>

          <Row justify="end" gutter={16}>
            <Col>
              <Button type="default" onClick={onPreviousClick}>
                Back
              </Button>
            </Col>
            <Col>
              <Button type="primary" onClick={onNextClick}>
                Next
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default JobDetails;
