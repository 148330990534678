import React, { useState, useEffect } from "react"
import { Spin, Steps } from "antd"
import CreateProfile from "./components/CreateProfile"
import ProfessionalExperience from "./components/ProfessionalExperience"
import Skills from "./components/Skills"
import { useFinishOnboardingMutation } from "../../api/apiSlices/workerApiSlice"
import { useNavigate, useParams } from "react-router-dom"
import { Routes } from "../../constants/routes"
import Education from "./components/Education"
import "./styles.scss"
import { getQueryParams } from "../../util/getQueryParams"

const UserExperience = ({ current }) => {
  const { step } = useParams()
  const { mode } = getQueryParams(window.location.search)
  const [currentStep, setCurrentStep] = useState(0)
  const onChangeStep = step => setCurrentStep(step)
  let navigate = useNavigate()

  console.log("mode", mode)

  const isEditMode = mode === "edit"

  useEffect(() => {
    if (step !== undefined) {
      const parsedStep = parseInt(step)
      if (!isNaN(parsedStep)) {
        setCurrentStep(parsedStep)
      } else {
        console.error("Invalid step:", step)
      }
    }
  }, [step])

  const onNextClick = () => {
    isEditMode
      ? navigate(Routes.WORKER_PROFILE)
      : setCurrentStep(currentStep + 1)
  }

  const onPreviousClick = () => {
    setCurrentStep(currentStep - 1)
  }

  const onFinishOnboarding = () => {
    navigate(Routes.WORKER_PROFILE)
  }
  const handleSkip = () => {
    onFinishOnboarding()
  }

  const skipStep = () => {
    setCurrentStep(currentStep + 1)
  }

  return (
    <div className="userExperience">
      {!isEditMode && (
        <Steps
          current={currentStep}
          items={[
            {
              title: "Create your profile"
            },
            {
              title: "Experience"
            },
            {
              title: "Education"
            },
            {
              title: "Characteristics"
            }
          ]}
          onChange={onChangeStep}
        />
      )}
      {currentStep === 0 && (
        <CreateProfile
          wrapperClassName="onboardingSteps"
          onNextClick={onNextClick}
          handleSkip={handleSkip}
          skipStep={skipStep}
          isEditMode={isEditMode}
        />
      )}
      {currentStep === 1 && (
        <ProfessionalExperience
          wrapperClassName="onboardingSteps"
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
          skipStep={skipStep}
          isEditMode={isEditMode}
        />
      )}
      {currentStep === 2 && (
        <Education
          wrapperClassName="onboardingSteps"
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
          skipStep={skipStep}
          isEditMode={isEditMode}
        />
      )}
      {currentStep === 3 && (
        <Skills
          wrapperClassName="onboardingSteps"
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
          skipStep={onFinishOnboarding}
          onFinishOnboarding={onFinishOnboarding}
          isEditMode={isEditMode}
        />
      )}
    </div>
  )
}

export default UserExperience
