import {
  ADD_CUSTOM_AVAILABILITY,
  ADD_RECURRING_AVAILABILITY,
  ADD_WORKER_SKILL,
  DELETE_WORK_EXPERIENCE,
  GET_WORKER_INFO_URL,
  SAVE_WORKER_EXPERIENCE,
  SAVE_WORKER_PROFILE_INFO,
  SAVE_WORKER_EDUCATION,
  CREATE_GIG,
  DELETE_WORK_EDUCATION,
  GET_WORKER_EDUCATION,
  EDIT_WORKER_EDUCATION
} from "../../constants/endpoints"
import { apiSlice } from "../apiSlice"

export const workerApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    updateWorkerProfileInfo: builder.mutation({
      query: data => ({
        url: SAVE_WORKER_PROFILE_INFO,
        method: "POST",
        body: data
      })
    }),
    getWorkerProfileInfo: builder.query({
      query: () => ({
        url: GET_WORKER_INFO_URL
      })
    }),
    updateWorkerExperience: builder.mutation({
      query: data => ({
        url: SAVE_WORKER_EXPERIENCE,
        method: "POST",
        body: data
      })
    }),
    deleteWorkExpereience: builder.mutation({
      query: id => ({
        url: DELETE_WORK_EXPERIENCE.replace(":id", id),
        method: "DELETE"
      })
    }),
    updateWorkerSkills: builder.mutation({
      query: data => ({
        url: ADD_WORKER_SKILL,
        method: "POST",
        body: data
      })
    }),
    addCustomAvailability: builder.mutation({
      query: data => ({
        url: ADD_CUSTOM_AVAILABILITY,
        method: "POST",
        body: data
      })
    }),
    finishOnboarding: builder.mutation({
      query: data => ({
        url: ADD_RECURRING_AVAILABILITY,
        method: "POST",
        body: data
      })
    }),
    updateWorkerEducation: builder.mutation({
      query: data => ({
        url: SAVE_WORKER_EDUCATION,
        method: "POST",
        body: data
      })
    }),
    editWorkerEducation: builder.mutation({
      query: data => ({
        url: EDIT_WORKER_EDUCATION.replace(":id", data.id),
        method: "PATCH",
        body: data
      })
    }),
    getWorkerEducationInfo: builder.query({
      query: id => ({
        url: GET_WORKER_EDUCATION.replace(":id", id)
      })
    }),

    deleteWorkerEducation: builder.mutation({
      query: id => ({
        url: DELETE_WORK_EDUCATION.replace(":id", id),
        method: "DELETE"
      })
    }),
    createGig: builder.mutation({
      query: data => ({
        url: CREATE_GIG,
        method: "POST",
        body: data
      })
    })
  }),

  overrideExisting: false
})

export const {
  useUpdateWorkerProfileInfoMutation,
  useGetWorkerProfileInfoQuery,
  useUpdateWorkerExperienceMutation,

  useDeleteWorkExpereienceMutation,
  useDeleteWorkerEducationMutation,
  useUpdateWorkerSkillsMutation,
  useAddCustomAvailabilityMutation,
  useFinishOnboardingMutation,
  useCreateGigMutation,

  useUpdateWorkerEducationMutation,
  useGetWorkerEducationInfoQuery,
  useEditWorkerEducationMutation
} = workerApiSlice
