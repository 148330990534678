import React, { useEffect, useState } from "react"
import {
  Typography,
  Button,
  Flex,
  Select,
  Radio,
  Spin,
  Switch,
  Space
} from "antd"
import { useNavigate } from "react-router-dom"

import { useGetNonProfitOrgsQuery } from "../../api/apiSlices/donationApiSlice"

import { PayPalIcon, PiggyBank, StripeIcon } from "../../assets/rawSvgs"
import { Routes } from "../../constants/routes"
import { getUser } from "../../api/auth"
import "./styles.scss"

const { Title, Text, Link } = Typography

function Donation() {
  const user = getUser()
  const [selectedOrganization, setSelectedOrganization] = useState(null)
  const [paymentLink, setPaymentLink] = useState(null)
  const [platform, setPlatform] = useState("stripe")
  const navigate = useNavigate()
  const [showRecurring, setShowRecurring] = useState(false)

  const { data: nonProfitOrgs, isLoading: isNonProfitOrgsLoading } =
    useGetNonProfitOrgsQuery()
  const [orgRecurringLinks, setOrgRecurringLinks] = useState([])

  const handlePlatformChange = e => {
    if (showRecurring) {
      setShowRecurring(false)
      setOrgRecurringLinks([])
      setPaymentLink(null)
    }

    const value = e.target.value
    setPlatform(value)
    const organization = nonProfitOrgs?.find(
      org => org.id === selectedOrganization
    )

    const nonRecurringLink = organization.payment_links
      .filter(link => link.is_recurring === false)
      .find(link => link.provider === platform)
    const recurringLinks = organization.payment_links
      .filter(link => link.is_recurring)
      .filter(link => link.provider === value)

    setOrgRecurringLinks(recurringLinks)
    if (organization && nonRecurringLink) {
      setPaymentLink(nonRecurringLink.link)
    }
  }

  const handleOrganizationChange = id => {
    if (showRecurring) {
      setShowRecurring(false)
      setOrgRecurringLinks([])
      setPaymentLink(null)
    }
    setSelectedOrganization(id)

    const organization = nonProfitOrgs?.find(org => org.id === id)
    const nonRecurringLink = organization.payment_links
      .filter(link => link.is_recurring === false)
      .find(link => link.provider === platform)
    const recurringLinks = organization.payment_links
      .filter(link => link.is_recurring)
      .filter(link => link.provider === platform)

    setOrgRecurringLinks(recurringLinks)
    if (organization && nonRecurringLink) {
      setPaymentLink(nonRecurringLink.link)
    }
  }

  const options = nonProfitOrgs?.map(org => ({
    value: org.id,
    label: org.name
  }))

  useEffect(() => {
    if (nonProfitOrgs?.length > 0 && !selectedOrganization) {
      const defaultOrg =
        nonProfitOrgs.find(org => org.default) || nonProfitOrgs[0]

      const nonRecurringLink = defaultOrg.payment_links
        .filter(link => link.is_recurring === false)
        .find(link => link.provider === platform)
      const recurringLinks = defaultOrg.payment_links
        .filter(link => link.is_recurring)
        .filter(link => link.provider === platform)

      setOrgRecurringLinks(recurringLinks)
      if (defaultOrg && nonRecurringLink) {
        setPaymentLink(nonRecurringLink.link)
      }
      setSelectedOrganization(defaultOrg.id)
    }
  }, [nonProfitOrgs, platform, selectedOrganization])

  const navigateToSettings = () => {
    navigate(Routes.SETTINGS + "?tab=donations")
  }

  const proceedToPayment = () => {
    if (paymentLink) {
      let link = paymentLink
      if (platform === "stripe") link = link + `?prefilled_email=${user.email}`
      window.open(link, "_blank")
    }
  }

  return (
    <div style={{ padding: "0 120px" }}>
      <Spin spinning={isNonProfitOrgsLoading}>
        <Flex justify="flex-end">
          <Button type="link" onClick={navigateToSettings}>
            View Donation History
          </Button>
        </Flex>
        <Flex vertical gap="32px">
          <div>
            <Flex gap="16px" style={flexContainerStyle}>
              <Flex>
                <PiggyBank />
              </Flex>

              <Flex vertical gap="8px">
                <Title level={4}>Monetary Donation</Title>
                <Text>
                  Donate cash to your favourite nonprofit. You can choose how
                  much to give and even donate a percentage of your purchase
                  profits. Your contribution helps support important programs
                  and initiatives.
                </Text>
              </Flex>
            </Flex>
          </div>

          <Flex gap="16px" vertical>
            <label style={{ color: "#000000E0" }}>Select Platform</label>
            <Radio.Group
              value={platform}
              onChange={handlePlatformChange}
              style={{ display: "flex", gap: "32px" }}
              buttonStyle="outline"
              className="RadioButton"
            >
              <Radio value="paypal">
                <PayPalIcon />
                PayPal
              </Radio>
              <Radio value="stripe">
                <StripeIcon />
                Stripe
              </Radio>
            </Radio.Group>

            <Flex vertical gap="10px">
              <label style={{ color: "#000000E0" }}>Select Non-Profit</label>
              <Select
                value={selectedOrganization}
                style={{ color: "#000000E0" }}
                options={options}
                onChange={handleOrganizationChange}
              />
              <Link
                href={nonProfitOrgs?.find(org => org.default)?.learn_more_link}
                target="_blank"
                className="linkTag"
              >
                Click here to learn more about{" "}
                {nonProfitOrgs?.find(org => org.default)?.name}
              </Link>
            </Flex>
            {selectedOrganization && orgRecurringLinks?.length > 0 && (
              <Flex vertical gap={8}>
                <Flex gap={8} align="center">
                  <Switch
                    checked={showRecurring}
                    onChange={checked => {
                      if (checked) setPaymentLink(null)
                      setShowRecurring(checked)
                    }}
                  />
                  <Title level={5}>Recurring Donation Options</Title>
                </Flex>
                {showRecurring && (
                  <Flex vertical>
                    <Text>Select on of the following donation option</Text>
                    <Radio.Group value={paymentLink}>
                      <Space direction="vertical">
                        {orgRecurringLinks.map(link => (
                          <Radio
                            value={link.link}
                            onChange={() => setPaymentLink(link.link)}
                          >
                            {link.name}
                          </Radio>
                        ))}
                      </Space>
                    </Radio.Group>
                  </Flex>
                )}
              </Flex>
            )}
          </Flex>

          <Flex gap="12px" justify="flex-end">
            <Button
              type="primary"
              disabled={!paymentLink}
              onClick={proceedToPayment}
            >
              Proceed to Payment
            </Button>
          </Flex>
        </Flex>
      </Spin>
    </div>
  )
}

export default Donation

const flexContainerStyle = {
  backgroundColor: "rgba(250, 173, 20, 1)",
  paddingTop: "32px",
  paddingBottom: "32px",
  paddingRight: "24px",
  paddingLeft: "24px",
  borderRadius: "8px"
}
