import React from "react"
import { Flex, Layout, Avatar, Typography, Dropdown } from "antd"
import appLogoSmall from "../../assets/common/logoSmall.png"
import useRouterNavigation from "../../hooks/useNavigate"
import { Routes } from "../../constants/routes"
import { UserOutlined } from "@ant-design/icons"
import { removeJWTToken, removeRefreshToken, removeUser } from "../../api/auth"
import { useGetProfileDataQuery } from "../../api/apiSlices/userApiSlice"
import "./styles.scss"

const { Text } = Typography
const { Header } = Layout

const DonationHeaderNav = () => {
  const { data } = useGetProfileDataQuery()
  const { navigateTo } = useRouterNavigation()

  console.log("data", data)

  const handleLogOut = () => {
    removeJWTToken()
    removeRefreshToken()
    removeUser()
    navigateTo(Routes.LOGIN)
  }

  const items = [
    {
      label: (
        <Text onClick={() => navigateTo(Routes.SELECT_PLATFORM)}>
          Switch Services
        </Text>
      ),
      key: "0"
    },
    {
      label: <Text onClick={handleLogOut}>Logout</Text>,
      key: "1"
    }
  ]

  return (
    <Header className="nav-container">
      <Flex justify="space-between" align="center" style={{ width: "100%" }}>
        <Flex gap={48} align="center">
          <div className="cursor-pointer">
            <img
              src={appLogoSmall}
              alt="app logo"
              onClick={() => navigateTo(Routes.DONATION)}
            />
          </div>
        </Flex>
        <Flex gap="middle" align="center">
          <Dropdown
            menu={{
              items
            }}
            trigger={["click"]}
            overlayStyle={{ width: "165px" }}
          >
            <a
              style={{ lineHeight: "32px" }}
              onClick={e => e.preventDefault()}
              href="*"
            >
              <Avatar
                size={32}
                icon={<UserOutlined />}
                src={data?.user?.profile_picture?.url}
                style={{ backgroundColor: "#3DC2DC" }}
              />
            </a>
          </Dropdown>
        </Flex>
      </Flex>
    </Header>
  )
}

export default DonationHeaderNav
