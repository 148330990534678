// src/api/endpoints/informationModalApi.js

import { apiSlice } from "../apiSlice"; 

// Define your API endpoints
import { 
     STEPS
} from "../../constants/endpoints";

export const informationModalApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Fetch user information
    // getUserInfo: builder.query({
    //   query: () => ({
    //     url: GET_USER_INFO_URL,
    //     method: "GET",
    //   }),
    // }),

    // Update user information (based on the form submission)
    updateUserInfo: builder.mutation({
      query: (data) => ({
        url: STEPS,
        method: "PUT",
        body: data,
      }),
    }),

   
  }),
  overrideExisting: false,  
});

export const {
 
  useUpdateUserInfoMutation,
} = informationModalApiSlice;
