import AccountDeletion from "./AccountDeletion"
import AccountPrivacy from "./AccountPrivacy"
import ConsentManagement from "./ConsentManagement"
import FeedbackAndSupport from "./FeedbackAndSupport"
import ConnectSocialAccounts from "./ConnectSocialAccounts"
import Donations from "./Donations"

const tabs = {
  "consent-management": ConsentManagement,
  "account-deletion": AccountDeletion,
  feedback: FeedbackAndSupport,
  "account-privacy": AccountPrivacy,
  "connect-social-accounts": ConnectSocialAccounts,
  donations: Donations
}

export default tabs
