import React from "react"
import { Input } from "antd"

const defaultFn = () => ""

const CustomInput = props => {
  const {
    inputwd = "",
    icon = "",
    labelClass = "",
    label = "",
    errorMessage = "",
    InputIconProp = "",
    placeholder = "",
    type = "text",
    value = "",
    onChange = defaultFn,
    onBlur = defaultFn,
    suffix = "",
    name = "",
    inputProps = {},
    description = "",
    required = false,
    className = ""
  } = props

  return (
    <>
      <div className={inputwd}>
        {label && (
          <label className={`${labelClass} Input_label`}>
            {label}
            {required && <span style={{ color: "red" }}>*</span>}
          </label>
        )}
        {description && (
          <p
            className="text-22-400"
            style={{ marginBottom: "4px", color: "#00000073" }}
          >
            {description}
          </p>
        )}
        {type === "password" ? (
          <Input.Password
          className={`Input ${className}`}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            onBlur={onBlur}
            name={name}
            {...inputProps}
          />
        ) : (
          <Input
            className={`${className}`}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            suffix={suffix ? suffix : undefined}
            onBlur={onBlur}
            name={name}
            {...inputProps}
          />
        )}
        {icon && (
          <span className={`${InputIconProp} Input_IconWrap`}>{icon}</span>
        )}
        {errorMessage && <p className="Input_errorMessage">{errorMessage}</p>}
      </div>
    </>
  )
}
export default CustomInput
