import React from 'react'
import { Modal , Flex , DatePicker ,Form ,Select ,label ,Button}  from 'antd'
import CustomInput from '../../../components/customInput/CustomInput'
import CustomTextArea from '../../../components/customInput/CustomTextArea'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import * as Yup from "yup"
import { useFormik } from "formik"
import { useUpdateWorkerEducationMutation } from '../../../api/apiSlices/workerApiSlice'
import { openNotification } from "../../../util/openNotifications"
import { useEditWorkerEducationMutation } from '../../../api/apiSlices/workerApiSlice'
import DayJs from 'dayjs';
import { setOnboardingStatus } from "./onboardingStatus"

export const validationSchema = Yup.object({
  school: Yup.string().required("School is required"),
  degree: Yup.string().required("Degree name is required"),
  start_year: Yup.number().nullable().typeError("Start year must be a number"),
  start_month: Yup.number(),
  end_year: Yup.number()
    .nullable()
    .typeError("End year must be a number")
    .test(
      "end-year-after-start-year",
      "End year must not be before the start year",
      function (end_year) {
        const { start_year, start_month, end_month } = this.parent;

        
        if (!start_year || !end_year) return true;

        
        if (end_year > start_year) return true;

        
        if (end_year === start_year) {
          if (end_month && start_month) {
            
            if (end_month < start_month) return false;
          }
        }
    

        return end_year >= start_year;
      }
    ),
  end_month: Yup.number()
   
});






function EducationInputModal({initialData = null,onClose}) {


  const initialState = initialData
  ? {
      ...initialData
    }
  : {
    school:"",
    degree:"",
    field_of_study:"",
    start_month:"",
    start_year:"",
    end_month:"",
    end_year:"",
    grade:"",
    description:""
    };

  
  const isEditMode = initialData?.id
  const [addWorkerEducation, { isLoading }] =
    useUpdateWorkerEducationMutation()
  const [editWorkerEducation]= useEditWorkerEducationMutation()  

  const { values , errors , touched , handleSubmit , handleBlur , setValues}=useFormik(
    {
      initialValues: initialState,
      validationSchema:validationSchema,
      onSubmit: values =>{
      // const data ={ ...values}
       if (isEditMode) {
       
        editWorkerEducation( values  )
          .unwrap()
          .then(() => {
           
            onClose(true);
          })
          .catch((error) => {
            
            openNotification({ type: "error", message: error?.message });
          });
      } else {
        setOnboardingStatus("step3", true);
        addWorkerEducation(values)
        
          .unwrap()
          .then(() => {
           
            onClose(true);
          })
          .catch((error) => {
           
            openNotification({ type: "error", message: error?.message });
          });
      }
    },
 
      }
      
    
  )
  // console.log("values", values);
  return (
    <Modal
    title="Add Education"
    icon={<ExclamationCircleOutlined />}
    open
    cancelText="Cancel"
    onCancel={onClose}
     
     okText={isEditMode ? "Edit" : "Save"}
      
     
      onOk={handleSubmit}
    okButtonProps={{ disabled: isLoading }}
     confirmLoading={isLoading}
    // footer={[
    //     <Button key="back" >
    //       Cancel
    //     </Button>,
    //     <Button onClick={handleSubmit} key="submit" type="primary" >
    //         Add Education
    //     </Button>,
    //   ]}
    width="660px"
  >
   
      <CustomInput
        name="School"
        label="School"
        inputwd="w-100 mb-3"
        placeholder="Ex. Boston University"
        className="mb-3"
        required
         value={values?.school}
         onChange={e => setValues({ ...values, school: e.target.value })}
         errorMessage={touched["school"] && errors?.school}
        onBlur={handleBlur}
      
      />
      <CustomInput
        name="Degree"
        label="Degree"
        inputwd="w-100 mb-3"
        placeholder="Ex. Bachelor’s"
        className="mb-3"
        required
        value={values?.degree}
        onChange={e => setValues({ ...values,  degree: e.target.value })}
        errorMessage={touched["degree"] && errors?.degree}
        onBlur={handleBlur}
      
      />
     
      <CustomInput
        name="Field of study"
        label="Field of study"
        inputwd="w-100 mb-3 mt-3"
        placeholder="Ex. Business"
        className="mb-3"
        value={values?.field_of_study}
        onChange={e => setValues({ ...values,  field_of_study: e.target.value })}
       
      />
     
    <Flex vertical  gap='16px'>
      <div className='pickerPadding'>          
        <div>
            <label className={`Input_label`}>
              Start date
            </label>
         </div>

   
            <Flex gap='16px'>
        
       
         <DatePicker 
            value={values.start_month  ? DayJs(values.start_month) : ""}
            onChange={(_, dateString) =>
              setValues({ ...values, start_month: dateString })
            }
            picker="month" 
            format="MM"
            style={{ width: '100%' , height:'40px' }} />

         <DatePicker  
            value={values.start_year ? DayJs(values.start_year) : ""}
            onChange={(_, dateString) =>
              setValues({ ...values, start_year: dateString })
            }
            picker="year" style={{ width: '100%', height:'40px' }}/>
           

          </Flex>

        </div>

        <div >


        <div>
            <label className={`Input_label`}>
               End date
            </label>
        </div>
     

        
         <Flex gap='16px' >
          
         <DatePicker  
          value={values?.end_month ? DayJs(values.end_month) : ""}
          onChange={(_, dateString) =>
            setValues({ ...values, end_month: dateString })
          }
          picker="month" 
          format="MM"
          style={{ width: '100%' , height:'40px'}}/>


         <DatePicker  
          value={values?.end_year ? DayJs(values.end_year) : ""}
          onChange={(_, dateString) =>
            setValues({ ...values, end_year: dateString })
          }
          picker="year"  style={{ width: '100%', height:'40px' }}/>
           
          </Flex>
          {errors.end_year && touched.end_year && (
          <span style={{ color: 'red', marginTop: "5px",  
            display: "block", width: "100%",}}>
            {errors.end_year}
          </span>
        )}
          </div>


         </Flex>
    

      
    <CustomInput
        name="Grade"
        label="Grade"
        inputwd="w-100 mb-3 mt-3"
        placeholder="Enter here"
        className="mb-3"
        value={values?.grade}
        onChange={e => setValues({ ...values,  grade: e.target.value })}
      />


      <CustomTextArea
        name="description"
        label="Description"
        inputwd="w-100 mb-3 mt-3"
        placeholder="Write a brief description of responsibilities."
        className="mb-3"
        value={values?.description}
        onChange={e => setValues({ ...values, description: e.target.value })}
        inputProps={{
          autoSize: {
            minRows: 2,
            maxRows: 3
          }
        }}
      />
   
  </Modal>
  )
}

export default EducationInputModal
