import { useEffect } from "react"
import { Layout } from "antd"
import { useLocation } from "react-router-dom"

import useRouterNavigation from "../../hooks/useNavigate"

import { userHasValidToken } from "../../api/auth"
import LoginHeader from "../../components/loginHeader/LoginHeader"

import { Routes } from "../../constants/routes"
import AdminChat from "../../pages/chat-management/AdminChat"

const { Content } = Layout

export default function PublicRouteValidator({ children, page }) {
  const location = useLocation()

  const { navigateTo } = useRouterNavigation()
console.log('location.pathname',location.pathname,Routes.ADMIN_CHAT , Routes.LANDING)
  useEffect(() => {

    if (location.pathname === Routes.LANDING || location.pathname === `${Routes.ADMIN_CHAT}/`) {
      return; 
    }

    if (userHasValidToken()) {
      navigateTo(location.state?.from || Routes.SETTINGS, { replace: true })
    } 
    
    else {
      navigateTo(Routes.LOGIN, { replace: true })
    }
  
 
    // if (!getUser()?.email) {
    //   navigateTo(Routes.LOGIN)
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout>
      <LoginHeader />
      <Content>{children}</Content>
    </Layout>
  )
}
